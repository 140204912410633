import React, { Component } from 'react';
import './ImportModal.css';
import { Button,Modal, ModalHeader, ModalBody, ModalFooter,
  Form,FormGroup,Label,ButtonGroup,FormText,Row,Col,Progress  } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import Dropzone from 'react-dropzone'
import csv from 'csv';
import moment from 'moment';
import 'moment/locale/it';
import ScrollToBottom from 'react-scroll-to-bottom';
import { css } from 'glamor';
moment.locale("it");
const ROOT_CSS = css({
  height: "50vh",
  width: "100%",
  backgroundColor:"#f3f3f3",
  padding:5,

});

class FilterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      acceptedFiles:[],
      csvRows:[],
      importProcesso:true,
      importVoce:true,
      importGruppo:true,
      importMacchina:true,
      importRunning:false,
      importingRowIndex:0,
      actualData:[],
      csvRowsSuccess:[],
      csvRowsWarning:[],
      csvRowsError:[],
      fullLog:[]
    };
    this.toggle = this.toggle.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.logMessage=this.logMessage.bind(this);
    this.startImport = this.startImport.bind(this);
    this.importRow = this.importRow.bind(this);
    this.endImport = this.endImport.bind(this);
    this.onImportProcessoChange = this.onImportProcessoChange.bind(this);
    this.onImportVoceChange = this.onImportVoceChange.bind(this);
    this.onImportGruppoChange = this.onImportGruppoChange.bind(this);
    this.onImportMacchinaChange = this.onImportMacchinaChange.bind(this);
    
    
    this.fetchInit={ method: 'GET',
    headers: new Headers(),
    mode: 'cors',
    cache: 'default' };
    
  }
  componentDidMount () {
      if (this.props.onMounted) {
          this.props.onMounted({
            toggle: () => this.toggle()
          })
      }      
  }
  componentWillUnmount() {
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
      acceptedFiles:[],
      csvRows:[],
      fullLog:[],      
      importProcesso:true,
      importVoce:true,
      importGruppo:true,
      importMacchina:true,
      importRunning:false,
      importingRowIndex:0
    });
  }
  onDrop(acceptedFiles) {
    if(acceptedFiles.length>0){
      if(!acceptedFiles[0].name.endsWith('.csv')) { this.setState({ rejected: true }) }
      
      this.setState({acceptedFiles})
      const reader = new FileReader();
      reader.onload = () => {        
        csv.parse(reader.result,{delimiter:";", columns:this.props.headers,trim:true, from :2 }, (err, csvRows) => {            
            this.setState({csvRows});            
        });
      };


      reader.readAsBinaryString(acceptedFiles[0]);
    }
  }
  onImportProcessoChange(importProcesso) {
    this.setState({ importProcesso });
  }
  onImportVoceChange(importVoce) {
    this.setState({ importVoce });
  }
  onImportGruppoChange(importGruppo) {
    this.setState({ importGruppo });
  }
  onImportMacchinaChange(importMacchina) {
    this.setState({ importMacchina });
  }
  logMessage(logText,logType=4,errMsg,data)
  {
    let cssClass="text-muted"
    
    switch(logType)
    {
      case 1:
        {
          cssClass="text-success"
          break
        }
      case 2:
        {
          cssClass="text-warning"
          break
        }
      case 3:
        {
          cssClass="text-danger"
          break
        }
      case 4:
          {
            cssClass="text-muted"
            break
          }
      default:
        {
          cssClass="text-muted"
          break
        }
    }
    const fullLog=this.state.fullLog.concat({logText:logText,cssClass:cssClass,dateTime:moment().toDate(),err:errMsg,data:data})
    this.setState({fullLog:fullLog})
  }
  startImport(){
    this.setState({
      importRunning:true,
      importingRowIndex:0,
      actualData:[],
      csvRowsSuccess:[],
      csvRowsWarning:[],
      csvRowsError:[],
      fullLog:[]})
    this.logMessage("Importazione avviata");      
    this.importRow(this.state.importingRowIndex)
  }
  importRow(index)
  {
    if(this.state.csvRows.length>index)
    {
      const currentRow=this.state.csvRows[index];

      var myInit = { method: 'GET',
        //headers: myHeaders,
        mode: 'cors',
        cache: 'default' };
      var path=process.env.REACT_APP_MARGINALITA_GET+"?Row_ID=eq."+currentRow.Row_ID+"";
      fetch(path,myInit)
        .then(res => res.json())
        .then(res =>{
        const actualRow=res[0]

        this.logMessage("Importazione della riga "+currentRow.Row_ID+" in corso.")
      var upLst=[]
      var colVals;
      if(this.state.importProcesso && (!actualRow || currentRow.Processo!==String(actualRow.Processo)))
      {
        upLst.push({row:currentRow.Row_ID,col:"Processo",val:String(currentRow.Processo)});      
      }
      if(this.state.importVoce &&  (!actualRow || currentRow.Voce!==String(actualRow.Voce)))
      {
        upLst.push({row:currentRow.Row_ID,col:"Voce",val:String(currentRow.Voce)});
      }
      if(this.state.importGruppo &&  (!actualRow || currentRow.Gruppo!==String(actualRow.Gruppo)))
      {
        colVals=this.props.cols.find(function(item){
          return item.data==="Gruppo"
        }).source
        if(colVals.find((el)=>{return el===String(currentRow.Gruppo)}))
        {
          upLst.push({row:currentRow.Row_ID,col:"Gruppo",val:String(currentRow.Gruppo)});
        }
        else{
          
        }
      }
      if(this.state.importMacchina &&  (!actualRow || currentRow.Macchina!==String(actualRow.Macchina)))
      {
        upLst.push({row:currentRow.Row_ID,col:"Macchina",val:String(currentRow.Macchina)});
      }
      if(upLst.length>0)
      {
        var myInit = {
          method: 'POST',
          headers:{
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          mode: 'cors',
          cache: 'default',                
          body: JSON.stringify({
            myid_row_provv: -1, 
            mycol_name: "",
            myvalue:{array:upLst},
            myflag:"json",
            user_id:this.props.user,
            myflagdelete:""
          }) 
        };
    
        var url=process.env.REACT_APP_MARGINALITA_UPDATE
        var context=this
        fetch(url,myInit).then( response => {
          if (!response.ok) { throw response }
          return response.json()  //we only get here if there is no error
        })
        .then( json => {
          const success=context.state.csvRowsSuccess.concat(currentRow)            
          context.setState({importingRowIndex:index+1,csvRowsSuccess:success})
          context.logMessage("Riga "+currentRow.Row_ID+" importata correttamente.",1)
          context.importRow(index+1)          
        })
        .catch( err => {
          var mex="";
          if (err.text) {
            err.json().then( errorMessage => {              
              console.log(errorMessage)
              const errors=context.state.csvRowsError.concat({err:err,data:currentRow})
              context.logMessage("La riga "+currentRow.Row_ID+" ha generato un errore.",3,(<span>{errorMessage.hint}<br/><i>{errorMessage.message}</i></span>),currentRow)
              context.setState({importingRowIndex:index+1,csvRowsError:errors})
              context.importRow(index+1)
            })
          } else {
            mex='Error.' // Hardcoded error here
            const errors=context.state.csvRowsError.concat({err:err,data:currentRow})
            context.logMessage("La riga "+currentRow.Row_ID+" ha generato un errore.",3,mex,currentRow)
            context.setState({importingRowIndex:index+1,csvRowsError:errors})
            context.importRow(index+1)
          }
        })

      }
      else{
        const warning=this.state.csvRowsWarning.concat(currentRow)
          
        this.setState({importingRowIndex:index+1,csvRowsWarning:warning})
        this.logMessage("La riga "+currentRow.Row_ID+" non contiene variazioni e verrà ignorata.",2)
        this.importRow(index+1)
      }
     })
      
      
      
    }
    else{
      this.endImport()
    }
  }
  endImport(){
    this.logMessage("Importazione terminata");
  }
  render() {    
    const {acceptedFiles,csvRows,importRunning,importingRowIndex,csvRowsSuccess,csvRowsError,csvRowsWarning,fullLog } = this.state;

    let logger,uploader;
    if(!importRunning)
    {
      let files,rows;
      if (acceptedFiles.length>0)
      {
        files=
        <ul className="list-group mt-2">
          {acceptedFiles.length > 0 && acceptedFiles.map(acceptedFile => (
            <li className="list-group-item  list-group-item-success" key="acceptedFile.name">
              {acceptedFile.name}
            </li>
          ))}
        </ul>
        rows=
            <div>Righe trovate: {csvRows.length}</div>
      }

      uploader=
        <div>
          <Dropzone onDrop={this.onDrop} multiple={false} minSize={0}>
            {({getRootProps, getInputProps,isDragActive,isDragAccept,isDragReject}) => (
              <section>
                <div {...getRootProps({className: 'dropzone'})}>
                  <input {...getInputProps()} />
                  {!isDragActive && (<span>Trascina qui il file da importare o clicca per selezionarlo.</span>)}
                  {isDragActive && !isDragReject &&  (<span>Rilascia qui il file.</span>)}                  
                  {isDragReject && (<span>Questo file non è nel formato corretto.</span>)}

                  {files}
                  {rows}
                </div>
              </section>
            )}
          </Dropzone>
          <Form>            
            <Row>
              <Col md={12} className="mt-4">
                <legend>Colonne da importare</legend>
              </Col>
              <Col md={3}className="mt-2" >        
                <FormGroup>
                  <Label>Processo</Label>
                  <div>
                    <ButtonGroup>
                      <Button color="primary" outline onClick={() => this.onImportProcessoChange(true)} active={this.state.importProcesso}>Si</Button>
                      <Button color="primary" outline onClick={() => this.onImportProcessoChange(false)} active={!this.state.importProcesso}>No</Button>
                    </ButtonGroup>
                  </div>
                </FormGroup>   
              </Col>
              <Col md={3} className="mt-2">        
                <FormGroup>
                  <Label>Voce</Label>
                  <div>
                    <ButtonGroup>
                      <Button color="primary" outline onClick={() => this.onImportVoceChange(true)} active={this.state.importVoce}>Si</Button>
                      <Button color="primary" outline onClick={() => this.onImportVoceChange(false)} active={!this.state.importVoce}>No</Button>
                    </ButtonGroup>
                  </div>
                </FormGroup>   
              </Col>
              <Col md={3} className="mt-2">        
                <FormGroup>
                  <Label>Gruppo</Label>
                  <div>
                    <ButtonGroup>
                      <Button color="primary" outline onClick={() => this.onImportGruppoChange(true)} active={this.state.importGruppo}>Si</Button>
                      <Button color="primary" outline onClick={() => this.onImportGruppoChange(false)} active={!this.state.importGruppo}>No</Button>
                    </ButtonGroup>
                  </div>
                </FormGroup>   
              </Col>
              <Col md={3} className="mt-2">        
                <FormGroup>
                  <Label>Macchina</Label>
                  <div>
                    <ButtonGroup>
                      <Button color="primary" outline onClick={() => this.onImportMacchinaChange(true)} active={this.state.importMacchina}>Si</Button>
                      <Button color="primary" outline onClick={() => this.onImportMacchinaChange(false)} active={!this.state.importMacchina}>No</Button>
                    </ButtonGroup>
                  </div>
                </FormGroup>   
              </Col>              
              <Col md={12} className="">
                <FormText >Seleziona le colonne del file che verranno prese in considerazione dall'import.</FormText>
              </Col>
            </Row>                    
          </Form>
        </div>
    }
    else{

      let current=importingRowIndex
      logger=
        <div>
          <div className="text-center">{current} di {csvRows.length}</div>
          <Progress bar value={current} max={csvRows.length} color="info" >{current}</Progress>


          <div className="text-center">Andamento</div>
          <Progress multi>
            <Progress bar value={csvRowsSuccess.length} max={csvRows.length} color="success">{csvRowsSuccess.length}</Progress>
            <Progress bar value={csvRowsWarning.length} max={csvRows.length} color="warning">{csvRowsWarning.length}</Progress>
            <Progress bar value={csvRowsError.length} max={csvRows.length} color="danger">{csvRowsError.length}</Progress>
          </Progress>
          <div className="mt-2">
            <ScrollToBottom className={ ROOT_CSS }>
              {fullLog.map((item, index) => (
                <span key={index} className={"d-block mb-2 "+item.cssClass} >
                  {moment(item.dateTime).format("L LTS")} - {item.logText}
                  {item.err && (<span className="small d-block">{item.err}</span>)}
                </span>
              ))}
            </ScrollToBottom>
            
          </div>
        </div>
    }
    

    return (  
       <Modal isOpen={this.state.modal} size="xl" scrollable={true} backdrop="static" toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.state.dataLoaded?this.toggle:null}>
            Importazione dati da CSV
          </ModalHeader>
          <ModalBody>
            {uploader}
            {logger}
          </ModalBody>
          <ModalFooter>
            <Button color="primary"disabled={csvRows.length===0}  onClick={csvRows.length>0?this.startImport:null}>Avvia</Button>
            <Button color="secondary" onClick={this.toggle}>Chiudi</Button>
          </ModalFooter>
        </Modal>  
    );
    
  }
}

export default withNamespaces('common')(FilterModal);
