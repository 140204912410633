import React, { Component } from 'react';
import './Footer.css';
import { withNamespaces } from 'react-i18next';

class Footer extends Component {
    // constructor(props){
    //     super(props);
       
    // }
    
    render() {
        const { t } = this.props;
        return (      
            <footer className="footer">
                <div className="container-fluid">
                <div className="row text-muted">
                    <div className="col-6 text-left">
                    {/* <ul className="list-inline">
                        <li className="list-inline-item">
                        <a className="text-muted" href="#">Support</a>
                        </li>
                        <li className="list-inline-item">
                        <a className="text-muted" href="#">Help Center</a>
                        </li>
                        <li className="list-inline-item">
                        <a className="text-muted" href="#">Privacy</a>
                        </li>
                        <li className="list-inline-item">
                        <a className="text-muted" href="#">Terms of Service</a>
                        </li>
                    </ul> */}
                    </div>
                    <div className="col-6 text-right">
                    <p className="mb-0">
                        © 2019 - {t('settings:vendor')}
                    </p>
                    </div>
                </div>
                </div>
            </footer>
        );
    }
}

export default withNamespaces('app','settings')(Footer);
