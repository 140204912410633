import React, { Component } from 'react';
import './FilterModal.css';
import { Button,ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label,FormText
,TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import AsyncSelect from 'react-select/lib/Async';
import { withNamespaces } from 'react-i18next';

import { DateRangePicker, createStaticRanges } from 'react-date-range';
import {it} from 'react-date-range/dist/locale'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import moment from 'moment';
import 'moment/locale/it';
moment.locale("it")
const dateFormat="YYYY-MM-DD";

var staticRanges = [
  ...createStaticRanges([{
      label: 'Mese corrente',
      range: () => ({
        startDate: moment().startOf('month').toDate(),
        endDate: moment().endOf('month').toDate()                
      })
    },
    {
      label: 'Ultimo mese',
      range: () => ({
        startDate: moment().add(-1,"month").startOf('month').toDate(),
        endDate: moment().add(-1,"month").endOf('month').toDate()
      })
    },
    {
      label: '30 giorni',
      range: () => ({
        startDate: moment().add(-30,"days").toDate(),
        endDate: moment().toDate()
      })
    },
    {
      label: 'Anno corrente',
      range: () => ({
        startDate: moment().startOf('year').toDate(),
        endDate: moment().endOf('year').toDate()                
      })
    },
    {
      label: 'Ultimo anno',
      range: () => ({
        startDate: moment().add(-1,"years").startOf('year').toDate(),
        endDate: moment().add(-1,"years").endOf('year').toDate()
      })
    },
    {
      label: '12 mesi',
      range: () => ({
        startDate: moment().add(-1,"years").toDate(),
        endDate: moment().toDate()
      })
    },
    {
      label: 'Ultimi 2 anni',
      range: () => ({
        startDate: moment().add(-2,"years").startOf('year').toDate(),
        endDate: moment().add(-1,"years").endOf('year').toDate()
      })
    },
    {
      label: 'Ultimi 3 anni',
      range: () => ({
        startDate: moment().add(-3,"years").startOf('year').toDate(),
        endDate: moment().add(-1,"years").endOf('year').toDate()
      })
    }
  ])
];

class FilterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      activeTab: '1',
      dataLoaded:false,
      selectedOptionAgenti:null,
      selectedOptionClientiFatture:null,
      selectedOptionClientiDestinazioni:null,
      selectedOptionOv:null,
      ignoraPeriodo:false,
      dateRangePicker: {
        selection: {
          startDate: moment().add(-1,'years').toDate(),
          endDate: moment().toDate(),
          key: 'selection',
        },
      }
    };
    this.loadData = this.loadData.bind(this);

    this.toggle = this.toggle.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.handleChangeClientiFatture = this.handleChangeClientiFatture.bind(this);
    this.handleChangeClientiDestinazioni = this.handleChangeClientiDestinazioni.bind(this);
    this.handleChangeOv = this.handleChangeOv.bind(this);
    this.onIgnoraPeriodoChange = this.onIgnoraPeriodoChange.bind(this);
    

    this.fetchInit={ method: 'GET',
    headers: new Headers(),
    mode: 'cors',
    cache: 'default' };
    
  }
  componentDidMount () {
      if (this.props.onMounted) {
          this.props.onMounted({
            toggle: () => this.toggle()
          })
      }      
  }
  componentWillUnmount() {
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  getOv(searchTerm){
    return fetch(process.env.REACT_APP_PREV_CONS_OV_GET+"?NrOV=ilike.*"+searchTerm+"*",this.fetchInit)
    .then(res =>res.json())
    .then((ogg)=>{
      if(ogg && ogg.length>0)
      {
        return ogg.map(function(item) {
          return {label:item.NrOV,value:item.NrOV};
        });
      }
      return [] })
    
    
  }

  getClientiFatture(searchTerm){
    return fetch(process.env.REACT_APP_PREV_CONS_CLIENTI_FATTURE_GET+"?ClienteFatturazione=ilike.*"+searchTerm+"*",this.fetchInit)
    .then(res =>res.json())
    .then((ogg)=>{
      if(ogg && ogg.length>0)
      {
        return ogg.map(function(item) {
          return {label:item.ClienteFatturazione,value:item.ClienteFatturazione};
        });
      }
      return [] })
    
    
  }

  getClientiDestinazioni(searchTerm){
    return fetch(process.env.REACT_APP_PREV_CONS_CLIENTI_DESTINAZIONI_GET+"?ClienteDestinazione=ilike.*"+searchTerm+"*",this.fetchInit)
    .then(res =>res.json())
    .then((ogg)=>{
      if(ogg && ogg.length>0)
      {
        return ogg.map(function(item) {
          return {label:item.ClienteDestinazione,value:item.ClienteDestinazione};
        });
      }
      return [] })
    
    
  }
  
  loadData(){
    this.setState({
        dataLoaded: true
      });
    this.props.onLoadDataClicked(
      moment(this.state.dateRangePicker.selection.startDate).format(dateFormat),
      moment(this.state.dateRangePicker.selection.endDate).format(dateFormat),
      this.state.selectedOptionClientiFatture,
      this.state.selectedOptionClientiDestinazioni,
      this.state.selectedOptionOv,
      this.state.ignoraPeriodo
      );
    this.toggle();
  }
  handleChangeClientiFatture(selectedOptionClientiFatture) {
    this.setState({ selectedOptionClientiFatture });
  }
  handleChangeClientiDestinazioni(selectedOptionClientiDestinazioni) {
    this.setState({ selectedOptionClientiDestinazioni });
  }
  handleChangeOv(selectedOptionOv) {
    this.setState({ selectedOptionOv });
  }
  handleRangeChange(which,payload){
    //console.log(which, payload);
    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
    });
  }
  onIgnoraPeriodoChange(ignoraPeriodo) {
    this.setState({ ignoraPeriodo });
  }
  render() {    
    const { selectedOptionClientiFatture,selectedOptionClientiDestinazioni,selectedOptionOv } = this.state;
    
    return (  
       <Modal isOpen={this.state.modal} size="xl" backdrop="static" toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.state.dataLoaded?this.toggle:null}>
            Seleziona i dati da visualizzare
          </ModalHeader>
          <ModalBody>
            <Form>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggleTab('1'); }}
                >
                  Periodo
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggleTab('2'); }}
                >
                  Filtri
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div className="p-4">
                    <div className="container">
                      <FormGroup>
                        <Label for="exampleText">Seleziona il periodo da visualizzare</Label>
                        <FormText >
                          <DateRangePicker
                            locale={it}
                            onChange={this.handleRangeChange.bind(this, 'dateRangePicker')}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            className={'PreviewArea'}
                            months={2}
                            direction="horizontal"
                            ranges={[this.state.dateRangePicker.selection]}
                            staticRanges={staticRanges}
                            inputRanges={[]}
                          />
                        </FormText>                
                      </FormGroup> 
                    </div>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="p-4">
                    <div className="container">
                    <FormGroup>
                      <Label for="selectAgenti">Ignora periodo</Label>
                      <div>
                        <ButtonGroup>
                          <Button color="primary" outline onClick={() => this.onIgnoraPeriodoChange(true)} active={this.state.ignoraPeriodo}>Si</Button>
                          <Button color="primary" outline onClick={() => this.onIgnoraPeriodoChange(false)} active={!this.state.ignoraPeriodo}>No</Button>
                        </ButtonGroup>
                        <FormText >Attenzione. Disattivare il filtro sul periodo potrebbe causare un carico di lavoro eccessivo sul proprio browser.</FormText>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label for="selectOv">Filtra gli ordini di vendita</Label>
                      <AsyncSelect 
                        id="selectOv"
                        isMulti 
                        cacheOptions 
                        defaultOptions                        
                        loadOptions={this.getOv.bind(this)}
                        value ={selectedOptionOv}    
                        onChange={this.handleChangeOv}
                         />
                    </FormGroup>
                    <FormGroup>
                      <Label for="selectClientiFatture">Filtra i clienti fatture</Label>
                      <AsyncSelect 
                        id="selectClientiFatture"
                        isMulti 
                        cacheOptions 
                        defaultOptions                        
                        loadOptions={this.getClientiFatture.bind(this)}
                        value={selectedOptionClientiFatture}     
                        onChange={this.handleChangeClientiFatture}
                         />
                    </FormGroup>
                    <FormGroup>
                      <Label for="selectClientiFatture">Filtra i clienti destinazione</Label>
                      <AsyncSelect 
                        id="selectClientiFatture"
                        isMulti 
                        cacheOptions 
                        defaultOptions                        
                        loadOptions={this.getClientiDestinazioni.bind(this)}
                        value={selectedOptionClientiDestinazioni}     
                        onChange={this.handleChangeClientiDestinazioni}
                         />
                    </FormGroup>
                    
                    </div>
                </div>
              </TabPane>
            </TabContent>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.loadData}>Carica dati</Button>
            <Button color="secondary" disabled={!this.state.dataLoaded}  onClick={this.state.dataLoaded?this.toggle:null}>Cancel</Button>
          </ModalFooter>
        </Modal>  
    );
    
  }
}

export default withNamespaces('common')(FilterModal);
