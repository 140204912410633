import React, { Component } from 'react';
import './Pivot.css';
import PivotUi from './pivot/PivotUi.js'

import Loader from './marginalita/Loader.js'
import FilterModal from './marginalita/FilterModal.js'
import IconFilter from 'react-feather/dist/icons/filter';
import { Button } from 'reactstrap';
import { withNamespaces } from 'react-i18next';


import moment from 'moment';


class Pivot extends Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.formatData = this.formatData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.modal = React.createRef();
    this.state = {      
      isLoaded: false,
      isLoadedAll: false,
      items:[],
      isFirstLoad:true,      
    };
    

  }
  modalMounted (callbacks) {
      this.modalCallbacks = callbacks
  }
  pivotMounted (callbacks) {
       this.pivotCallbacks = callbacks
  }
  loaderMounted (callbacks) {
      this.loaderCallbacks = callbacks
  }
  toggleModal(){
    this.modalCallbacks.toggle();
  }
  loadData(startDate,endDate,agenti,clientiFatture,clientiDestinazioni,ov,ignoraPeriodo,numeroFattura,numeroFatturaManuale,fatturabile,eliminato,annoCustom){   
    this.loaderCallbacks.show("Caricamento dati in corso...");
    
    var filtroAgenti="";
    if(agenti && agenti.length>0)
    {
      filtroAgenti="\""+agenti.map((i)=>{return i.value;}).join("\",\"")+"\"";
      filtroAgenti="&Agente=in.("+filtroAgenti+")";
    }
    var filtroClientiFatture="";
    if(clientiFatture && clientiFatture.length>0)
    {
      filtroClientiFatture="\""+clientiFatture.map((i)=>{return i.value;}).join("\",\"")+"\"";
      filtroClientiFatture="&RagSocFattura=in.("+filtroClientiFatture+")";
    }
    var filtroClientiDestinazioni="";
    if(clientiDestinazioni && clientiDestinazioni.length>0)
    {
      filtroClientiDestinazioni="\""+clientiDestinazioni.map((i)=>{return i.value;}).join("\",\"")+"\"";
      filtroClientiDestinazioni="&RagSocCliDestinazione=in.("+filtroClientiDestinazioni+")";
    }    
    var filtroOv="";
    if(ov && ov.length>0)
    {
      filtroOv="\""+ov.map((i)=>{return i.value;}).join("\",\"")+"\"";
      filtroOv="&NumeroOV=in.("+filtroOv+")";
    }    
    var filtroNumeroFattura="";
    if(numeroFattura && numeroFattura.length>0)
    {
      filtroNumeroFattura="\""+numeroFattura.map((i)=>{return i.value;}).join("\",\"")+"\"";
      filtroNumeroFattura="&NumeroFattura=in.("+filtroNumeroFattura+")";
    }
    var filtroNumeroFatturaManuale="";
    if(numeroFatturaManuale && numeroFatturaManuale.length>0)
    {
      filtroNumeroFatturaManuale="\""+numeroFatturaManuale.map((i)=>{return i.value;}).join("\",\"")+"\"";
      filtroNumeroFatturaManuale="&NumeroFatturaManuale=in.("+filtroNumeroFatturaManuale+")";
    }
    var filtroAnnoCustom="";
    if(annoCustom)
    {
      filtroAnnoCustom="&AnnoManuale=eq."+annoCustom+"";
    }
    var filtroFatturabile="";
    if(fatturabile && fatturabile!=="-1")
    {
      filtroFatturabile="&Fatturabile=eq."+fatturabile+"";
    }
    var filtroEliminato="";
    if(eliminato && eliminato!=="-1")
    {
      filtroEliminato="&Eliminato=eq."+eliminato+"";
    }
    var myHeaders = new Headers();
    //myHeaders.append("Range", "0-999");
    //myHeaders.append("Range-Unit", "items");
    var myInit = { method: 'GET',
               headers: myHeaders,
               mode: 'cors',
               cache: 'default' };
    
    var path=process.env.REACT_APP_MARGINALITA_GET;
    if(!ignoraPeriodo)
    { 
      path+="?Periodo=gte."+startDate+
      "&Periodo=lte."+endDate+"";
    }
    
    var filters=filtroAgenti+
    filtroClientiFatture+
    filtroClientiDestinazioni+
    filtroOv+
    filtroNumeroFattura+
    filtroNumeroFatturaManuale+
    filtroAnnoCustom+
    filtroFatturabile+
    filtroEliminato;
    if(ignoraPeriodo && filters.length>0)
    {
        filters="?"+filters.substring(1)
    }
    path+=filters;
    fetch(path,myInit)
      .then(res => res.json())
      .then(
        (result) => {
          this.loaderCallbacks.show("Rendering delle informazioni in corso...");
          // if(this.pivotCallbacks)
          // {
          //   this.pivotCallbacks.loadData(this.formatData(result));
          // }
          // else{
            this.setState({
              isLoaded: true,
              items: result            
            });
          //}
          this.loaderCallbacks.hide();
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
        
      )
  }
  formatData(items){
    return items.map(function(item) {
      item.DataFattura=moment(item.DataFattura).format("DD/MM/YYYY")
      item.DataOV=item.DataOV?moment(item.DataOV).format("DD/MM/YYYY"):""
      item.DataTrasporto=item.DataTrasporto?moment(item.DataTrasporto).format("DD/MM/YYYY"):""
      item.DataAggiornamento=item.DataAggiornamento?moment(item.DataAggiornamento).format("DD/MM/YYYY"):""
      item.Periodo=item.Periodo?moment(item.Periodo).format("DD/MM/YYYY"):""
      return item;
    });
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location === nextProps.location
       && this.state.items === nextState.items) {
      return false;
    } else {
      return true;
    }
  }
  componentDidMount() {
    if(this.state.isFirstLoad)
    {
      this.modalCallbacks.toggle();
    }
  }

  render() {
    // const { t } = this.props;
    const { error, isLoaded, items } = this.state;

    let header,body;
    if (error) {
      body=(
          <div className="card-body py-4 ">
            <div className="overflow-hidden table-container">
              <div>Error: {error.message}</div>
            </div>
          </div>
      );
    } else if (!isLoaded) {
      body=(
          <div className="card-body py-4 ">
            <div className="overflow-hidden table-container">
              <div></div>
            </div>
          </div>
      );              
        
    } else {
      if(items.length>0)
      {
        var d=this.formatData(items);        
      }

      header=(
            <div className="card-actions float-right">
              <div className="input-group input-group-sm">
                <Button className="mr-2" outline  color="primary" size="sm" onClick={this.toggleModal}><IconFilter></IconFilter></Button>
                <div className="input-group-append">
                  {/* <GridMenu 
                    OnExportCsv={this.exportCsv.bind(this)} 
                    OnClearFilters={this.clearFilters.bind(this)}  
                  /> */}
                </div>                
              </div>
            </div>
          );
      if(items.length>0)
      {
        body= (         
            <div className="card-body py-4 ">
              <div className="overflow-auto table-container">
                <PivotUi items={d} onMounted={this.pivotMounted.bind(this)}/>  
                
                {/* <Grid onMounted={this.gridMounted.bind(this)}  
                ref={this.grid} id="grid" items={d} cols={cols} headers={headers}
                onRendered={this.gridRendered.bind(this)}  />   */}
              </div>
            </div>   
        );
      } else{
        body= (         
            <div className="card-body py-4 ">
              <div className="overflow-hidden">
                 Nessun risultato trovato.
              </div>
            </div>   
        ); 
      }
      
    }
    
    return (
      <div className="card flex-fill">
        <div className="card-header">          
          {header}
          <h3 className="card-title mb-0">Dati <Loader onMounted={this.loaderMounted.bind(this)} /></h3>
          {/* <h6 className="card-subtitle text-muted">Lorem ipsum ipsem dolor sit amet.</h6> */} 
        </div>
        {body}
        <FilterModal id="modal" ref={this.modal} 
        onLoadDataClicked={this.loadData.bind(this)} 
        onMounted={this.modalMounted.bind(this)} 
        ></FilterModal>
      </div>
    );

    
  }
}

export default withNamespaces('common')(Pivot);
