import React, { Component } from 'react';
import './JobModal.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label,Col, Row} from 'reactstrap';
import AsyncSelect from 'react-select/lib/Async';
import { withNamespaces } from 'react-i18next';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import swal from '@sweetalert/with-react'
import moment from 'moment';
import 'moment/locale/it';
import { isNull } from 'util';
moment.locale("it")




class JobModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedOptionNumFattura:null,
      selectedOptionPeriodo:null,
    };

    this.toggle = this.toggle.bind(this);
    this.handleChangeNumFattura = this.handleChangeNumFattura.bind(this);
    this.handleChangePeriodo = this.handleChangePeriodo.bind(this);
    this.loadData = this.loadData.bind(this);
    

    this.fetchInit={ method: 'GET',
    headers: new Headers(),
    mode: 'cors',
    cache: 'default' };
    
  }
  componentDidMount () {
      if (this.props.onMounted) {
          this.props.onMounted({
            toggle: () => this.toggle()
          })
      }      
  }
  componentWillUnmount() {
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }
  loadData(){    
    
    if(!this.state.selectedOptionPeriodo || !this.state.selectedOptionNumFattura)

    {
      swal("Errore.","Tutti i campi sono obbligatori", "error"); 
    }
    else
    {
      var myInit = {
        method: 'POST',
        headers:{
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        mode: 'cors',
        cache: 'default',                
        body: JSON.stringify({
          userid:this.props.user,
          monthcd:this.state.selectedOptionPeriodo.value,
          nrfatt:parseInt(this.state.selectedOptionNumFattura.value),
          task: "CR",
          job_row_id: 0, 
        }) 
      };
  
      var url=process.env.REACT_APP_ETL_MANUALE
      var isErr=false;
      fetch(url,myInit)
        .then(res => {
          // if(res.ok)
          // {
            isErr=!res.ok;
            return res.json()
          //}
          // throw new Error(""res"");
        })
        .then(res => {
          console.log(res)
          if (isErr){
            swal("Errore.", res.hint?res.hint:res.message, "error"); 
          }
          else{
            swal("Schedulazione salvata.", "La schedulazione è stata salvata e verrà eseguita a breve.", "success");
          }
                
        });
    
        //this.props.onLoadDataClicked(this.state.selectedOptionNumFattura,this.state.selectedOptionPeriodo);
      this.toggle();
    }
  }
  getNumFattura(searchTerm){
    return fetch(process.env.REACT_APP_MARGINALITA_NUM_FATTURA_GET+(searchTerm?"?NumeroFattura=eq."+searchTerm+"":""),this.fetchInit)
    .then(res =>res.json())
    .then((ogg)=>{
      if(ogg && ogg.length>0)
      {
        return ogg.map(function(item) {
          return {label:item.NumeroFattura,value:item.NumeroFattura};
        });
      }
      return [] })
  }
  getPeriodo(searchTerm){
    return fetch(process.env.REACT_APP_MARGINALITA_PERIODO_GET+"?Periodo=ilike.*"+searchTerm+"*",this.fetchInit)
    .then(res =>res.json())
    .then((ogg)=>{
      if(ogg && ogg.length>0)
      {
        return ogg.map(function(item) {
          return {label:item.Periodo,value:item.PeriodoMonthCd};
        });
      }
      return [] })
  }  
  
  handleChangeNumFattura(selectedOptionNumFattura) {
    this.setState({ selectedOptionNumFattura });
  }
  handleChangePeriodo(selectedOptionPeriodo) {
    this.setState({ selectedOptionPeriodo });
  }
  render() {    
    const {selectedOptionNumFattura,selectedOptionPeriodo} = this.state;
    

    return (  
       <Modal isOpen={this.state.modal} size="sm" scrollable={true} backdrop="static" toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.state.dataLoaded?this.toggle:null}>
            Seleziona i dati da aggiornare
          </ModalHeader>
          <ModalBody>
            <Form>
                <div className="p-4">
                    <div className="container">                         
                        <FormGroup>
                          <Label for="selectNumFattura">Numero fattura</Label>
                          <AsyncSelect 
                            id="selectNumFattura"                             
                            cacheOptions 
                            defaultOptions                        
                            loadOptions={this.getNumFattura.bind(this)}
                            value ={selectedOptionNumFattura}    
                            onChange={this.handleChangeNumFattura}
                            />
                        </FormGroup>                
                        <FormGroup>
                          <Label for="selectMese">Periodo</Label>                          
                          <AsyncSelect 
                            id="selectNumFattura"                             
                            cacheOptions 
                            defaultOptions                        
                            loadOptions={this.getPeriodo.bind(this)}
                            value ={selectedOptionPeriodo}    
                            onChange={this.handleChangePeriodo}
                            />
                        </FormGroup>      
                    </div>
                </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.loadData}>Schedula aggiornamento</Button>
            <Button color="secondary" onClick={this.toggle}>Annulla</Button>
          </ModalFooter>
        </Modal>  
    );
    
  }
}

export default withNamespaces('common')(JobModal);
