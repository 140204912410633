import React, { Component } from 'react';
import './FilterModal.css';
import { Button,ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label,FormText,Col, Row
,TabContent, TabPane, Nav, NavItem, NavLink,Input } from 'reactstrap';
import classnames from 'classnames';
import AsyncSelect from 'react-select/lib/Async';
import { withNamespaces } from 'react-i18next';

import { DateRangePicker, createStaticRanges } from 'react-date-range';
import {it} from 'react-date-range/dist/locale'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import moment from 'moment';
import 'moment/locale/it';
import { isNull } from 'util';
moment.locale("it")
const dateFormat="YYYY-MM-DD";

var staticRanges = [
  ...createStaticRanges([{
      label: 'Mese corrente',
      range: () => ({
        startDate: moment().startOf('month').toDate(),
        endDate: moment().endOf('month').toDate()                
      })
    },
    {
      label: 'Ultimo mese',
      range: () => ({
        startDate: moment().add(-1,"month").startOf('month').toDate(),
        endDate: moment().add(-1,"month").endOf('month').toDate()
      })
    },
    {
      label: '30 giorni',
      range: () => ({
        startDate: moment().add(-30,"days").toDate(),
        endDate: moment().toDate()
      })
    },
    {
      label: 'Anno corrente',
      range: () => ({
        startDate: moment().startOf('year').toDate(),
        endDate: moment().endOf('year').toDate()                
      })
    },
    {
      label: 'Ultimo anno',
      range: () => ({
        startDate: moment().add(-1,"years").startOf('year').toDate(),
        endDate: moment().add(-1,"years").endOf('year').toDate()
      })
    },
    {
      label: '12 mesi',
      range: () => ({
        startDate: moment().add(-1,"years").toDate(),
        endDate: moment().toDate()
      })
    },
    {
      label: 'Ultimi 2 anni',
      range: () => ({
        startDate: moment().add(-2,"years").startOf('year').toDate(),
        endDate: moment().add(-1,"years").endOf('year').toDate()
      })
    }
    // ,
    // {
    //   label: 'Ultimi 3 anni',
    //   range: () => ({
    //     startDate: moment().add(-3,"years").startOf('year').toDate(),
    //     endDate: moment().add(-1,"years").endOf('year').toDate()
    //   })
    // }
  ])
];

class FilterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      activeTab: '1',
      dataLoaded:false,
      selectedOptionAgenti:null,
      selectedOptionClientiFatture:null,
      selectedOptionClientiDestinazioni:null,
      selectedOptionOv:null,
      selectedOptionNumFattura:null,//[{label:"9",value:"9"}],
      selectedOptionNumFatturaManuale:null,
      selectedCustomYear:"",//"2018"
      selectedFatturabile:"1",
      selectedEliminato:"0",
      ignoraPeriodo:false,//true
      dateRangePicker: {
        selection: {
          startDate: moment().add(-1,"month").startOf('month').toDate(),
          endDate: moment().add(-1,"month").endOf('month').toDate(),
          key: 'selection',
        },
      }
    };
    this.loadData = this.loadData.bind(this);

    this.toggle = this.toggle.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.handleChangeAgenti = this.handleChangeAgenti.bind(this);
    this.handleChangeClientiFatture = this.handleChangeClientiFatture.bind(this);
    this.handleChangeClientiDestinazioni = this.handleChangeClientiDestinazioni.bind(this);
    this.handleChangeOv = this.handleChangeOv.bind(this);
    this.handleChangeNumFattura = this.handleChangeNumFattura.bind(this);
    this.handleChangeNumFatturaManuale = this.handleChangeNumFatturaManuale.bind(this);
    this.handleChangeFatturabile = this.handleChangeFatturabile.bind(this);
    this.handleChangeEliminato = this.handleChangeEliminato.bind(this);
    this.handleChangeCustomYear = this.handleChangeCustomYear.bind(this);
    this.onIgnoraPeriodoChange = this.onIgnoraPeriodoChange.bind(this);
    

    this.fetchInit={ method: 'GET',
    headers: new Headers(),
    mode: 'cors',
    cache: 'default' };
    
  }
  componentDidMount () {
      if (this.props.onMounted) {
          this.props.onMounted({
            toggle: () => this.toggle()
          })
      }      
  }
  componentWillUnmount() {
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  getNumFattura(searchTerm){
    return fetch(process.env.REACT_APP_MARGINALITA_NUM_FATTURA_GET+(searchTerm?"?NumeroFattura=eq."+searchTerm+"":""),this.fetchInit)
    .then(res =>res.json())
    .then((ogg)=>{
      if(ogg && ogg.length>0)
      {
        return ogg.map(function(item) {
          return {label:item.NumeroFattura,value:item.NumeroFattura};
        });
      }
      return [] })
    
    
  }
  getNumFatturaManuale(searchTerm){
    return fetch(process.env.REACT_APP_MARGINALITA_NUM_FATTURA_MAN_GET+(searchTerm?"?NumeroFatturaManuale=eq."+searchTerm+"":""),this.fetchInit)
    .then(res =>res.json())
    .then((ogg)=>{
      if(ogg && ogg.length>0)
      {
        return ogg.map(function(item) {
          return {label:item.NumeroFatturaManuale,value:item.NumeroFatturaManuale};
        });
      }
      return [] })
    
    
  }
  getOv(searchTerm){
    return fetch(process.env.REACT_APP_MARGINALITA_OV_GET+"?NumeroOV=ilike.*"+searchTerm+"*",this.fetchInit)
    .then(res =>res.json())
    .then((ogg)=>{
      if(ogg && ogg.length>0)
      {
        return ogg.map(function(item) {
          return {label:item.NumeroOV,value:item.NumeroOV};
        });
      }
      return [] })
    
    
  }

  getClientiFatture(searchTerm){
    return fetch(process.env.REACT_APP_MARGINALITA_CLIENTI_FATTURE_GET+"?RagSocFattura=ilike.*"+searchTerm+"*",this.fetchInit)
    .then(res =>res.json())
    .then((ogg)=>{
      if(ogg && ogg.length>0)
      {
        return ogg.map(function(item) {
          return {label:item.RagSocFattura,value:item.RagSocFattura};
        });
      }
      return [] })
    
    
  }

  getClientiDestinazioni(searchTerm){
    return fetch(process.env.REACT_APP_MARGINALITA_CLIENTI_DESTINAZIONI_GET+"?RagSocCliDestinazione=ilike.*"+searchTerm+"*",this.fetchInit)
    .then(res =>res.json())
    .then((ogg)=>{
      if(ogg && ogg.length>0)
      {
        return ogg.map(function(item) {
          return {label:item.RagSocCliDestinazione,value:item.RagSocCliDestinazione};
        });
      }
      return [] })
    
    
  }

  getAgenti(searchTerm){
    return fetch(process.env.REACT_APP_MARGINALITA_AGENTI_GET+"?Agente=ilike.*"+searchTerm+"*",this.fetchInit)
    .then(res =>res.json())
    .then((ogg)=>{
      if(ogg && ogg.length>0)
      {
        return ogg.map(function(item) {
          return {label:item.Agente,value:item.Agente};
        });
      }
      return [] })
    
    
  }
  
  loadData(){
    this.setState({
        dataLoaded: true
      });
    this.props.onLoadDataClicked(
      moment(this.state.dateRangePicker.selection.startDate).format(dateFormat),
      moment(this.state.dateRangePicker.selection.endDate).format(dateFormat),
      this.state.selectedOptionAgenti,
      this.state.selectedOptionClientiFatture,
      this.state.selectedOptionClientiDestinazioni,
      this.state.selectedOptionOv,
      this.state.ignoraPeriodo,
      this.state.selectedOptionNumFattura,
      this.state.selectedOptionNumFatturaManuale,
      this.state.selectedFatturabile,
      this.state.selectedEliminato,
      this.state.selectedCustomYear
      );
    this.toggle();
  }

  handleChangeAgenti(selectedOptionAgenti) {
    this.setState({ selectedOptionAgenti });
  }
  handleChangeClientiFatture(selectedOptionClientiFatture) {
    this.setState({ selectedOptionClientiFatture });
  }
  handleChangeClientiDestinazioni(selectedOptionClientiDestinazioni) {
    this.setState({ selectedOptionClientiDestinazioni });
  }
  handleChangeOv(selectedOptionOv) {
    this.setState({ selectedOptionOv });
  }
  handleChangeNumFattura(selectedOptionNumFattura) {
    this.setState({ selectedOptionNumFattura });
  }
  handleChangeNumFatturaManuale(selectedOptionNumFatturaManuale) {
    this.setState({ selectedOptionNumFatturaManuale });
  }
  handleChangeFatturabile(e)
  {
    let selectedFatturabile=e.target.value;
    this.setState({ selectedFatturabile });
  }
  handleChangeEliminato(e)
  {
    let selectedEliminato=e.target.value;
    this.setState({ selectedEliminato });
  }  
  handleChangeCustomYear(e)
  {
    let selectedCustomYear=e.target.value;
    this.setState({ selectedCustomYear });
  }
  handleRangeChange(which,payload){
    //console.log(which, payload);
    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
    });
  }
  onIgnoraPeriodoChange(ignoraPeriodo) {
    this.setState({ ignoraPeriodo });
  }
  render() {    
    const { selectedOptionAgenti,
      selectedOptionClientiFatture,
      selectedOptionClientiDestinazioni,
      selectedOptionOv,
      selectedOptionNumFattura,
      selectedOptionNumFatturaManuale,
      selectedCustomYear,
      selectedFatturabile,
      selectedEliminato } = this.state;
    

    return (  
       <Modal isOpen={this.state.modal} size="xl" scrollable={true} backdrop="static" toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.state.dataLoaded?this.toggle:null}>
            Seleziona i dati da visualizzare
          </ModalHeader>
          <ModalBody>
            <Form>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggleTab('1'); }}
                >
                  Periodo
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggleTab('2'); }}
                >
                  Filtri
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div className="p-4">
                    <div className="container">
                      <FormGroup>
                        <Label for="exampleText">Seleziona il periodo da visualizzare</Label>
                        <FormText >
                          <DateRangePicker
                            locale={it}
                            onChange={this.handleRangeChange.bind(this, 'dateRangePicker')}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            className={'PreviewArea'}
                            months={2}
                            direction="horizontal"
                            ranges={[this.state.dateRangePicker.selection]}
                            staticRanges={staticRanges}
                            inputRanges={[]}
                          />
                        </FormText>                
                      </FormGroup> 
                    </div>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="p-4">
                    <div className="container">
                    <FormGroup>
                      <Label for="selectAgenti">Ignora periodo</Label>
                      <div>
                        <ButtonGroup>
                          <Button color="primary" outline onClick={() => this.onIgnoraPeriodoChange(true)} active={this.state.ignoraPeriodo}>Si</Button>
                          <Button color="primary" outline onClick={() => this.onIgnoraPeriodoChange(false)} active={!this.state.ignoraPeriodo}>No</Button>
                        </ButtonGroup>
                        <FormText >Attenzione. Disattivare il filtro sul periodo potrebbe causare un carico di lavoro eccessivo sul proprio browser.</FormText>
                      </div>
                    </FormGroup>                    
                    <Row form>
                      <Col md={4}>                      
                        <FormGroup>
                          <Label for="selectOv">Filtra gli ordini di vendita</Label>
                          <AsyncSelect 
                            id="selectOv"
                            isMulti 
                            cacheOptions 
                            defaultOptions                        
                            loadOptions={this.getOv.bind(this)}
                            value ={selectedOptionOv}    
                            onChange={this.handleChangeOv}
                            />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="selectNumFattura">Filtra i numeri fattura</Label>
                          <AsyncSelect 
                            id="selectNumFattura"
                            isMulti 
                            cacheOptions 
                            defaultOptions                        
                            loadOptions={this.getNumFattura.bind(this)}
                            value ={selectedOptionNumFattura}    
                            onChange={this.handleChangeNumFattura}
                            />
                        </FormGroup> 
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="selectNumFatturaManuale">Filtra i numeri fattura manuali</Label>
                          <AsyncSelect 
                            id="selectNumFatturaManuale"
                            isMulti 
                            cacheOptions 
                            defaultOptions                        
                            loadOptions={this.getNumFatturaManuale.bind(this)}
                            value ={selectedOptionNumFatturaManuale}    
                            onChange={this.handleChangeNumFatturaManuale}
                            />
                        </FormGroup> 
                      </Col>
                    </Row>
                    
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="selectClientiFatture">Filtra i clienti fatture</Label>
                          <AsyncSelect 
                            id="selectClientiFatture"
                            isMulti 
                            cacheOptions 
                            defaultOptions                        
                            loadOptions={this.getClientiFatture.bind(this)}
                            value={selectedOptionClientiFatture}     
                            onChange={this.handleChangeClientiFatture}
                            />
                        </FormGroup>
                      </Col>
                      <Col md={6}>                      
                        <FormGroup>
                          <Label for="selectClientiFatture">Filtra i clienti destinazione</Label>
                          <AsyncSelect 
                            id="selectClientiFatture"
                            isMulti 
                            cacheOptions 
                            defaultOptions                        
                            loadOptions={this.getClientiDestinazioni.bind(this)}
                            value={selectedOptionClientiDestinazioni}     
                            onChange={this.handleChangeClientiDestinazioni}
                            />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Label for="selectAgenti">Filtra gli agenti</Label>
                      <AsyncSelect 
                        id="selectAgenti"
                        isMulti 
                        cacheOptions 
                        defaultOptions                       
                        loadOptions={this.getAgenti.bind(this)}
                        value={selectedOptionAgenti}  
                        onChange={this.handleChangeAgenti}
                         />
                    </FormGroup>
                    <Row form>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="selectFatturabile">Fatturabile</Label>
                          <Input type="select" name="select" id="selectFatturabile" value={selectedFatturabile} onChange={this.handleChangeFatturabile}>
                            <option value="-1">Tutti</option>
                            <option  value="1">Si</option>
                            <option  value="0">No</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="selectEliminato">Eliminato</Label>
                          <Input type="select" name="select" id="selectEliminato" value={selectedEliminato} onChange={this.handleChangeEliminato}>
                            <option value="-1">Tutti</option>
                            <option value="1">Si</option>
                            <option value="0">No</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="inputCustomYear">Anno custom</Label>
                          <Input type="number" name="customYear" id="inputCustomYear" value={selectedCustomYear} onChange={this.handleChangeCustomYear} />
                        </FormGroup>
                      </Col>
                    </Row>
                    </div>
                </div>
              </TabPane>
            </TabContent>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.loadData}>Carica dati</Button>
            <Button color="secondary" disabled={!this.state.dataLoaded}  onClick={this.state.dataLoaded?this.toggle:null}>Cancel</Button>
          </ModalFooter>
        </Modal>  
    );
    
  }
}

export default withNamespaces('common')(FilterModal);
