import React, { Component } from 'react';
import './Loader.css';
import { Spinner } from 'reactstrap';

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show:false,
      message:"Loading..."
    };
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    
  }
  componentDidMount () {
      if (this.props.onMounted) {
          this.props.onMounted({
            show: (message) => this.show(message),
            hide: () => this.hide()
          })
      }      
  }
  componentWillUnmount() {
  }

  show(message) {
    this.setState({
      show: true,
      message:message
    });
  }
  hide() {
    this.setState({
      show: false
    });
  }
  
  render() {    
    let { show,message } = this.state;
    if(show)
    {
      return (  
          <div className="text-primary d-inline-block c-loader"><Spinner color="primary" type="grow" size="sm" /> {message}</div>
      );
    }
    else{
      return ("");
    }
  }
}

export default Loader;
