import React, { Component } from 'react';
import './Admin.css';
import { NavLink } from 'react-router-dom';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { withNamespaces } from 'react-i18next';

import Home from "./admin/Home.js";
import Processi from "./admin/Processi.js";
import Voci from "./admin/Voci.js";
import Gruppi from "./admin/Gruppi.js";
import Macchine from "./admin/Macchine.js";
import Schedulazioni from "./admin/Schedulazioni.js";

const feather =require('feather-icons');

 

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
        feather.replace();
    
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location === nextProps.location) {
      return false;
    } else {      
      return true;
    }
  }
  
  render() {
    const { t,items,location } = this.props;

    return(
      <Switch location={location}>
          <Route exact path="/admin/" component={props => <Home {...props} items={items.find(p => p.key === "admin").routes}/>} location={location} />
          <Route exact path="/admin/processi/" component={Processi} location={location} />
          <Route exact path="/admin/voci/" component={Voci} location={location} />
          <Route exact path="/admin/gruppi/" component={Gruppi} location={location} />
          <Route exact path="/admin/macchine/" component={Macchine} location={location} />
          <Route exact path="/admin/schedulazioni/" component={Schedulazioni} location={location} />
          {/* Without this `Route`, we would get errors during
          the initial transition from `/` to `/hsl/10/90/50`
      */}
          <Route render={() => <div>Not Found</div>} />
        </Switch>
    );
    
  }
}

export default withNamespaces('app','settings')(Admin);
