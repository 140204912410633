module.exports ={
    cols:[
        {"data": "LegameDato","type":"text", "readOnly":true},
        {"data": "Offerta","type":"text", "readOnly":true},
        {"data": "NrOV","type":"text", "readOnly":true},
        {"data": "TipoOV","type":"text", "readOnly":true},
        {"data": "NumeroFattura","type":"text", "readOnly":true},
        {"data": "DataFattura","type":"date", "readOnly":true,dateFormat: 'DD/MM/YYYY',correctFormat: true},
        {"data": "CodiceClienteFatturazione","type":"text", "readOnly":true, width:120},
        {"data": "ClienteFatturazione","type":"text", "readOnly":true, width:250},
        {"data": "CodiceClienteDestinazione","type":"text", "readOnly":true, width:120},
        {"data": "ClienteDestinazione","type":"text", "readOnly":true, width:250},
        {"data": "Codice","type":"text", "readOnly":true, width:120},
        {"data": "Prodotto","type":"text", "readOnly":true, width:250},
        {"data": "CodArt","type":"text", "readOnly":true, width:120},
        {"data": "DescrizioneArticolo","type":"text", "readOnly":true, width:250},
        {"data": "Processo","type":"text", "readOnly":true},
        {"data": "Voce","type":"text", "readOnly":true},
        {"data": "Gruppo","type":"text", "readOnly":true},
        {"data": "Macchina","type":"text", "readOnly":true},
        {"data": "VenditaPanteraValuta","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data": "VenditaPanteraEur","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data": "VenditaLyraValuta","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data": "VenditaLyraCambioPantera","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data": "VenditaLyraCambioLyra","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data": "TotaleCostiPantera","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data": "TotaleCostiLyra","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data": "NrOrePantera","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data": "NrOreLyra","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data": "TotaleCostoOrePantera","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data": "TotaleCostoOreLyra","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data": "TotaleProvvigioni","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data": "Valuta","type":"text", "readOnly":true, width:70},
        {"data": "Quantita","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data": "MDCPantera","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data": "MDCLyra","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data": "MDCPercPantera","type":"numeric", "readOnly":true},
        {"data": "MDCPercLyra","type":"numeric", "readOnly":true},
        {"data": "DataInserimentoOfferta","type":"date", "readOnly":true,dateFormat: 'DD/MM/YYYY',correctFormat: true},
        {"data": "Revisione","type":"numeric", "readOnly":true, numericFormat: {pattern: '0',culture: 'it-IT'}},
        {"data": "QtaCons","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data": "CambioPantera","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data": "CambioLyra","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"NonPreventivato","type":"checkbox", "readOnly":true,className:"htCenter"},
        {"data":"Fatturabile","type":"checkbox", "readOnly":true,checkedTemplate: '1',uncheckedTemplate: '0',className:"htCenter"},
        
    ],
    headers:[
        "LegameDato",
        "Offerta",
        "NrOv",
        "TipoOV",
        "NumeroFattura",
        "DataFattura",
        "CodiceClienteFatturazione",
        "ClienteFatturazione",
        "CodiceClienteDestinazione",
        "ClienteDestinazione",
        "Codice",
        "Prodotto",
        "CodArt",
        "DescrizioneArticolo",
        "Processo",
        "Voce",
        "Gruppo",
        "Macchina",
        "VenditaPanteraValuta",
        "VenditaPanteraEur",
        "VenditaLyraValuta",
        "VenditaLyraCambioPantera",
        "VenditaLyraCambioLyra",
        "TotaleCostiPantera",
        "TotaleCostiLyra",
        "NrOrePantera",
        "NrOreLyra",
        "TotaleCostoOrePantera",
        "TotaleCostoOreLyra",
        "TotaleProvvigioni",
        "Valuta",
        "Quantità",
        "MDCPantera",
        "MDCLyra",
        "MDCPercPantera",
        "MDCPercLyra",
        "DataInserimentoOfferta",
        "Revisione",
        "QtaCons",
        "CambioPantera",
        "CambioLyra",
        "NonPreventivato",
        "Fatturabile"
    ]
}




