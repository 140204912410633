import React, { Component } from 'react';
import './App.css';
import TopNav from "./components/TopNav.js";
import LeftNav from "./components/LeftNav.js";
import Footer from "./components/Footer.js";
import Home from "./components/pages/Home.js";
import Marginalita from "./components/pages/Marginalita.js";
import Pivot from "./components/pages/Pivot.js";
import PrevVsCons from "./components/pages/PrevVsCons.js";
import Admin from "./components/pages/Admin.js";

import Keycloak from 'keycloak-js';
// import Page from "./components/pages/Page.js";


import { withNamespaces} from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

class App extends Component {
  constructor() {
    super();
    this.sideToggle = this.sideToggle.bind(this);
    
    this.state = {
      keycloak: null, authenticated: false,
      leftOpen: true,
      routes:[
          {
            "key":"home",
            path:"/",
            icon:"home",
            text:"glossary:home",
            desc:"glossary:home-desc",
            hideLeft:true
          },
          {
            "key":"marginalita",
            path:"/marginalita/",
            icon:"briefcase",
            text:"glossary:marginalita",
            desc:"glossary:marginalita-desc",
          },
          {
            "key":"pivot",
            path:"/pivot/",
            icon:"layout",
            text:"glossary:pivot",
            desc:"glossary:pivot-desc",
          },
          {
            "key":"prevVsCons",
            path:"/preventivi-vs-consuntivi/",
            icon:"trending-up",
            text:"glossary:prevVsCons",
            desc:"glossary:prevVsCons-desc",
          },
          {
            "key":"admin",
            path:"/admin/",
            routes:[
              {
                "key":"admin-processi",
                path:"/admin/processi/",
                icon:"align-justify",
                text:"glossary:admin-processi",
                desc:"glossary:admin-processi-desc",
              },
              {
                "key":"admin-voci",
                path:"/admin/voci/",
                icon:"align-justify",
                text:"glossary:admin-voci",
                desc:"glossary:admin-voci-desc",
              },
              {
                "key":"admin-gruppi",
                path:"/admin/gruppi/",
                icon:"align-justify",
                text:"glossary:admin-gruppi",
                desc:"glossary:admin-gruppi-desc",
              },
              {
                "key":"admin-macchine",
                path:"/admin/macchine/",
                icon:"align-justify",
                text:"glossary:admin-macchine",
                desc:"glossary:admin-macchine-desc",
              },
              {
                "key":"admin-schedulazioni",
                path:"/admin/schedulazioni/",
                icon:"clock",
                text:"glossary:admin-schedulazioni",
                desc:"glossary:admin-schedulazioni-desc",
              },
            ],
            icon:"settings",
            text:"glossary:admin",
            desc:"glossary:admin-desc",
          }
        ]      
    };
  }

  componentDidMount() {
    const keycloak = Keycloak('/keycloak.json');
    var thisApp=this;
    keycloak.init({onLoad: 'login-required'}).success(function(authenticated) {
      thisApp.setState({ keycloak: keycloak, authenticated: authenticated })
    }).error(function() {
      thisApp.setState({ keycloak: keycloak, authenticated: false })
    })
  }

  sideToggle() {    
    if(this.leftNav)
    {
      this.leftNav.toggle();
    }
  }

  leftNavMounted (callbacks) {
      this.leftNav = callbacks
  }

  render() {
    const { t } = this.props;
    if (this.state.keycloak) 
    {
      if (this.state.authenticated) 
      {
        const pageTitle=this.state.routes.map((route, index) => {
          if( route.routes)
          {
            let innerRoute=            
            route.routes.map((route2, index2) =>(<Route
              key={index2}
              path={route2.path}
              exact={true}
              component={() => " - "+t(route2.text)}
            />));
            return (
              <span>
                <Route
                  key={index}
                  path={route.path}
                  exact={false}
                  component={() => t(route.text)}
                />
                {innerRoute}
              </span>
            )
          }
          else
          { 
            return (
              <Route
                key={index}
                path={route.path}
                exact={true}
                component={() => t(route.text)}
              />
            )
          } 
        });
        return (
          <Router>
            <Route        
              render={({ location }) => (
            <div className="wrapper">          
              <LeftNav children={this.state.routes} toggled={this.state.leftOpen} onMounted={this.leftNavMounted.bind(this)}  location={location}></LeftNav>
                <div className="main">
                
                <TopNav keycloak={this.state.keycloak} OnSideToggle={this.sideToggle} pageTitle={pageTitle} location={location}></TopNav>
                <main role="main" className="content">
                  <div className="container-fluid p-0">
                    <div className="row">
                      <div className="col-12 col-xl d-flex">
                            <Switch location={location}>
                              <Route exact path="/" component={props => <Home {...props} items={this.state.routes}/>} location={location} />
                              <Route path="/marginalita/" component={props => <Marginalita {...props} keycloak={this.state.keycloak}/>} location={location} />                              
                              <Route exact path="/pivot/" component={Pivot} location={location} />
                              <Route exact path="/preventivi-vs-consuntivi/" component={PrevVsCons} location={location} />
                              <Route path="/admin/" component={props => <Admin {...props} items={this.state.routes}/>} location={location} />
                              {/* Without this `Route`, we would get errors during
                              the initial transition from `/` to `/hsl/10/90/50`
                          */}
                              <Route render={() => <div>Not Found</div>} />
                            </Switch>
                      </div>
                    </div>
                  </div>
                
                </main>
                <Footer></Footer>
              </div>
            </div> 
            )}
          />
          </Router>
        );
      }
      else 
        return (<div>Unable to authenticate!</div>)
    }
    return (
       <div className="pre-loader">
        <div className="loader-cont"></div>
      </div>
    );
    
  }
}
export default withNamespaces('app')(App);

