import React, { Component } from 'react';
import './TopNav.css';
import * as Icon from 'react-feather';

import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom'

import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem 
} from 'reactstrap';
class TopNav extends Component {
  constructor(props) {
    super(props);

    this.leftToggle = this.leftToggle.bind(this);
    this.logout = this.logout.bind(this);
    this.state = {
      isOpen: false,
      name: "",
      email: "",
      id: ""
    };

    this.props.keycloak.loadUserInfo().success(userInfo => {
      //console.log(userInfo);
        this.setState({name: userInfo.preferred_username, email: userInfo.email, id: userInfo.sub})
    });
  }
  logout(){
    //console.log("logout");
    this.props.history.push('/');
    this.props.keycloak.logout();
  }
  leftToggle(event) {
    event.preventDefault();
    this.props.OnSideToggle();
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location === nextProps.location
      && this.state.name === nextState.name
      && this.state.email === nextState.email) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    const { t } = this.props;
    return (   
      <Navbar color="light" light expand="md" className="shadow" key="topNav">
          <a href="#" className="sidebar-toggle d-flex mr-2" onClick={this.leftToggle} >
            <i className="hamburger align-self-center"></i>
          </a>
          <span className="navbar-text ml-auto d-xl-inline-block h3 font-weight-bold text-primary">
           {this.props.pageTitle}
          </span>
          <Nav className=" ml-auto" navbar>              
              <NavItem className="text-nowrap">
                <NavLink href="#"></NavLink>
              </NavItem>              
              <UncontrolledDropdown nav >
                <DropdownToggle nav caret>
                <span className="d-inline-block "><Icon.User /></span>                
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem header key="welcoome">
                  {t('glossary:user.welcome')}
                  </DropdownItem>  
                  <DropdownItem disabled  key="userInfo">
                  <span className="text-primary">{this.state.name}</span><br/>
                  <span className="small text-muted">{this.state.email}</span>
                  </DropdownItem>                  
                  <DropdownItem divider  key="divider" />
                  <DropdownItem onClick={this.logout } key="logout">
                  {t('glossary:user.logOut')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
        </Navbar>      
    );
  }
}

export default withRouter(withNamespaces('app')(TopNav));
