import React, { Component } from 'react';
import './PrevVsCons.css';

import Grid from './prev-cons/Grid.js'
import GridMenu from './prev-cons/GridMenu.js'
//import GridSearch from './prev-cons/GridSearch.js'
import Loader from './prev-cons/Loader.js'
import FilterModal from './prev-cons/FilterModal.js'
import RulesModal from './prev-cons/RulesModal.js'
import IconFilter from 'react-feather/dist/icons/filter';
import IconRules from 'react-feather/dist/icons/life-buoy';
import { Button } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import colsConfig from "./prev-cons/cols-config.js"

import moment from 'moment';
//import { CONTEXTMENU_ITEMS_MERGE_CELLS, CONTEXTMENU_ITEMS_SHOW_COLUMN } from 'handsontable/es/i18n/constants';

class PrevVsCons extends Component {
  constructor(props) {
    super(props);
    this.exportCsv = this.exportCsv.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.search = this.search.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleModalRules = this.toggleModalRules.bind(this);
    this.formatData = this.formatData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.grid = React.createRef();
    this.modal = React.createRef();
    this.modalRules = React.createRef();
    this.state = {      
      itemsCount:0,
      isLoaded: false,
      isLoadedAll: false,
      items:[],
      processi:[],
      gruppi:[],
      voci:[],
      macchine:[],
      showYear:2018,
      isFirstLoad:true,      
    };
    

  }
  exportCsv() {      
    this.gridCallbacks.exportCsv();
  }
  clearFilters() {      
    this.gridCallbacks.clearFilters();
  }
  search(value) {      
    this.gridCallbacks.search(value);
  }
  toggleModal(){
    this.modalCallbacks.toggle();
  }
  toggleModalRules(){
    this.modalRulesCallbacks.toggle();
  }
  gridMounted (callbacks) {
      this.gridCallbacks = callbacks
  }
  gridRendered(){
    this.loaderCallbacks.hide();
  }
  modalMounted (callbacks) {
      this.modalCallbacks = callbacks
  }
  modalRulesMounted (callbacks) {
      this.modalRulesCallbacks = callbacks
  }
  loaderMounted (callbacks) {
      this.loaderCallbacks = callbacks
  }
  gridNeedRefresh () {
      const {startDate,endDate,clientiFatture,clientiDestinazioni,ov,ignoraPeriodo}=this.state;
      this.loadData(startDate,endDate,clientiFatture,clientiDestinazioni,ov,ignoraPeriodo);
  }
  loadData(startDate,endDate,clientiFatture,clientiDestinazioni,ov,ignoraPeriodo){    
    this.setState({
      startDate:startDate,
      endDate:endDate,
      clientiFatture: clientiFatture,
      clientiDestinazioni: clientiDestinazioni,
      ov:ov,
      ignoraPeriodo:ignoraPeriodo     
    });
    
    this.loaderCallbacks.show("Caricamento dati in corso...");
    
    var filtroClientiFatture="";
    if(clientiFatture && clientiFatture.length>0)
    {
      filtroClientiFatture="\""+clientiFatture.map((i)=>{return i.value;}).join("\",\"")+"\"";
      filtroClientiFatture="&ClienteFatturazione=in.("+filtroClientiFatture+")";
    }
    var filtroClientiDestinazioni="";
    if(clientiDestinazioni && clientiDestinazioni.length>0)
    {
      filtroClientiDestinazioni="\""+clientiDestinazioni.map((i)=>{return i.value;}).join("\",\"")+"\"";
      filtroClientiDestinazioni="&ClienteDestinazione=in.("+filtroClientiDestinazioni+")";
    }
    
    var filtroOv="";
    if(ov && ov.length>0)
    {
      filtroOv="\""+ov.map((i)=>{return i.value;}).join("\",\"")+"\"";
      filtroOv="&NrOV=in.("+filtroOv+")";
    }
    var myHeaders = new Headers();
    //myHeaders.append("Range", "0-999");
    //myHeaders.append("Range-Unit", "items");
    var myInit = { method: 'GET',
               headers: myHeaders,
               mode: 'cors',
               cache: 'default' };
    
    var path=process.env.REACT_APP_PREV_CONS_GET;
    if(!ignoraPeriodo)
    { 
      path+="?DataFattura=gte."+startDate+
      "&DataFattura=lte."+endDate+"";
    }
    
    var filters=filtroClientiFatture+filtroClientiDestinazioni+filtroOv;
    if(ignoraPeriodo && filters.length>0)
    {
        filters="?"+filters.substring(1)
    }
    path+=filters;
    fetch(path,myInit)
      .then(res => res.json())
      .then(
        (result) => {
          this.loaderCallbacks.show("Rendering delle informazioni in corso...");
          
          if(this.gridCallbacks && result.length>0 && this.state.itemsCount>0)
          {
            this.gridCallbacks.loadData(this.formatData(result));
          }
          else{
            var height=document.getElementsByClassName('table-container')[0].clientHeight
            
            this.setState({
              gridHeight:height,
              itemsCount:result.length,
              isLoaded: true,
              items: result            
            });
            if(result.length===0)
            {
              this.loaderCallbacks.hide();
            }
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
        
      )
  }
  formatData(items){
    return items.map(function(item) {
      
      item.DataFattura=item.DataFattura?moment(item.DataFattura,"YYYY-MM-DD").format("DD/MM/YYYY"):""
      item.DataInserimentoOfferta=item.DataInserimentoOfferta?moment(item.DataInserimentoOfferta).format("DD/MM/YYYY"):""
      
      return item;
    });
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location === nextProps.location
       && this.state.items === nextState.items) {
      return false;
    } else {
      
      return true;
    }
  }
  componentDidMount() {
    
    if(this.state.isFirstLoad)
    {      
          this.modalCallbacks.toggle();
           
    }    
  }

  render() {
    // const { t } = this.props;
    const { error, isLoaded, items,gridHeight } = this.state;
    const cols=colsConfig.cols;
    const headers=colsConfig.headers;
    

    let header,body;
    if (error) {
      body=(
          <div className="card-body py-4 ">
            <div className="overflow-hidden table-container">
              <div>Error: {error.message}</div>
            </div>
          </div>
      );
    } else if (!isLoaded) {
      body=(
          <div className="card-body py-4 ">
            <div className="overflow-hidden table-container">
              <div></div>
            </div>
          </div>
      );              
        
    } else {
      if(items.length>0)
      {
        var d=this.formatData(items);
      }
      
      header=(
            <div className="card-actions float-right">
              <div className="input-group input-group-sm">
                <Button className="mr-2" outline  color="info" size="sm" onClick={this.toggleModalRules}><IconRules></IconRules></Button>
                <Button className="mr-2" outline  color="primary" size="sm" onClick={this.toggleModal}><IconFilter></IconFilter></Button>                
                {/* <GridSearch 
                  OnSearch={this.search.bind(this)}  />{' '}  */}
                <div className="input-group-append">
                  <GridMenu 
                    OnExportCsv={this.exportCsv.bind(this)} 
                    OnClearFilters={this.clearFilters.bind(this)}  
                  />
                </div>                
              </div>
            </div>
          );
      if(items.length>0)
      {
        body= (         
            <div className="card-body py-4 ">
              <div className="overflow-hidden table-container">
                <Grid onMounted={this.gridMounted.bind(this)}  height={gridHeight}
                ref={this.grid} id="grid" items={d} cols={cols} headers={headers}
                onRendered={this.gridRendered.bind(this)} onNeedRefresh={this.gridNeedRefresh.bind(this)} />  
              </div>
            </div>   
        );
      } else{
        body= (         
            <div className="card-body py-4 ">
              <div className="overflow-hidden table-container">
                 Nessun risultato trovato.
              </div>
            </div>   
        ); 
      }
      
    }
    
    return (
      <div className="card flex-fill">
        <div className="card-header">          
          {header}
          <h3 className="card-title mb-0">Dati <Loader onMounted={this.loaderMounted.bind(this)} /></h3>
          {/* <h6 className="card-subtitle text-muted">Lorem ipsum ipsem dolor sit amet.</h6> */} 
        </div>
        {body}
        <FilterModal id="modal" ref={this.modal} 
        onLoadDataClicked={this.loadData.bind(this)} 
        onMounted={this.modalMounted.bind(this)} 
        ></FilterModal>
        <RulesModal id="modal" ref={this.modalRules} 
        onNeedRefresh={this.gridNeedRefresh.bind(this)} 
        onMounted={this.modalRulesMounted.bind(this)} 
        ></RulesModal>
      </div>
    );

    
  }
}

export default withNamespaces('common')(PrevVsCons);
