import React, { Component } from 'react';
import './RulesModal.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import { Spinner } from 'reactstrap';
import IconCheckFalse from 'react-feather/dist/icons/square';
import IconCheckTrue from 'react-feather/dist/icons/check-square';
import IconX from 'react-feather/dist/icons/x';




class RulesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      dataLoaded:false,
      items:[]
    };

    this.renderChildren = this.renderChildren.bind(this)
    this.toggle = this.toggle.bind(this);    
    this.deleteRow = this.deleteRow.bind(this);    

    this.fetchInit={ method: 'GET',
    headers: new Headers(),
    mode: 'cors',
    cache: 'default' };
    
  }
  componentDidMount () {
      if (this.props.onMounted) {
          this.props.onMounted({
            toggle: () => this.toggle()
          })
      }
  }
  componentWillUnmount() {
    
  }

  deleteRow(e) {
    var modal=this;
    var id=e.currentTarget.dataset.rowId;
    var path=process.env.REACT_APP_CODICI_PRODOTTO_GET+"?row_id=eq."+id; 
    var myInit = {
      method: 'DELETE',      
      mode: 'cors',
      cache: 'default'
    };
    fetch(path,myInit)
      .then(res => {
        
        modal.loadCodiciProdotto().then(()=>{
          if (modal.props.onNeedRefresh) {modal.props.onNeedRefresh()}
        });
      },
      (error) => {
        console.log(error);
      }); 
  }
  toggle() {
    if(!this.state.modal)
    {      
      this.loadCodiciProdotto();
    }

    this.setState({
      modal: !this.state.modal
    });
  }
  loadCodiciProdotto(){      
    this.setState({
      dataLoaded: false,
      items:[]
    });
    return fetch(process.env.REACT_APP_CODICI_PRODOTTO_GET,this.fetchInit)
    .then(res =>res.json())
    .then((ogg)=>{      
          this.setState({
            dataLoaded: true,
            items:ogg
          });
       })
  }
  renderChildren() {
    const { items } = this.state;
    var modal=this;
    return items.map(function(d){        
      return(
          <tr className="" key={"tr_"+d.row_id}>
              <td className="align-middle">{d.codice?d.codice:"NULL"}</td>
              <td className="align-middle">{d.prodotto?d.prodotto:"NULL"}</td>
              <td className="align-middle text-center">{d.billable?<IconCheckTrue/>:<IconCheckFalse/>}</td>
              <td className="align-middle text-right"><Button color="danger" data-row-id={d.row_id} size="sm" onClick={modal.deleteRow}><IconX className="feather-sm" /></Button></td>
          </tr>
          )
    });
    
}
  render() {    

    const { dataLoaded,items} = this.state;
    let body;
    if(dataLoaded)
        {
          if(items.length===0)
          {
            body=(<div className="text-center text-muted">
              Nessuna regola applicata
            </div>);
          }
          else{
            body=(
                <div className="text-left">               
                  <div className="table-responsive">
                    <table cellPadding="0" cellSpacing="0" className="table table-striped table-hover table-sm">
                      <thead>
                        <tr>
                          <th>Codice</th>
                          <th>Prodotto</th>
                          <th className="text-center">Non Preventivato</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>{this.renderChildren()}</tbody>
                    </table>
                  </div>
                </div>
            );
          }
        }
        else{
            body=<Spinner color="primary" type="grow" size="sm" />
        }
    return (  
       <Modal isOpen={this.state.modal} size="md" backdrop="static" toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>
            Regole applicate ai prodotti
          </ModalHeader>
          <ModalBody>
            {body}
          </ModalBody>
          <ModalFooter>            
            <Button color="secondary" onClick={this.toggle}>Chiudi</Button>
          </ModalFooter>
        </Modal>  
    );
    
  }
}

export default withNamespaces('common')(RulesModal);
