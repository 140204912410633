import React, { Component } from 'react';
import './Popover.css';
import { Spinner } from 'reactstrap';


import { withNamespaces } from 'react-i18next';

class Popover extends Component {
  constructor(props) {
    super(props);
    this.margin=10
    this.width=250;
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      show: false,
      valueLoaded:false,
      value:null,
      top:0,
      left:0
    };
  }
  
  show(rowId,prop,element) {
    var coords=element.getBoundingClientRect()    
    this.setState({
      show:true,
      valueLoaded:false,
      top:coords.top -20,//coords.bottom,
      left:coords.left+coords.width+this.margin//coords.left-(this.width/2)+(coords.width/2)
    });
    var myInit = { method: 'GET',
               mode: 'cors',
               cache: 'default' };
    var path=process.env.REACT_APP_MARGINALITA_ORIGINAL_GET+"?Row_ID=eq."+rowId;    
    fetch(path,myInit)
      .then(res => res.json())
      .then(
        (result) => {
            this.setState({
                valueLoaded:true,
                value:result[0][prop] ,
            });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
        
      )
  }
  hide() {
    this.setState({
      show:false,
      value:null,
      top:0,
      left:0
    });
  }
  componentDidMount () {
      if (this.props.onMounted) {
          this.props.onMounted({
            show:(rowId,prop,element)=>this.show(rowId,prop,element),
            hide: () => this.hide()
          })
      }
      
  }
  render() {
    const { show,value,top,left,valueLoaded } = this.state;
    
    
    let body;
    if(show)
    {
        if(valueLoaded)
        {
          var val=value;
          if(val===null)
          {
            val="--";
          }
            body=(
                <div className="text-left">
                    <div>Valore originale da ETL</div>                    
                    <div className="text-primary font-weight-bold">{val}</div>
                    <div className="text-muted small">Per annullare le modifiche, cliccare con il tasto destro del mouse sulla cella e premere "Annulla modifiche"</div>                    
                </div>
            );
        }
        else{
            body=<Spinner color="primary" type="grow" size="sm" />
        }
        var style={top:top, left:left}
        return (   
        <div className="app-popover card shadow" style={style}>
            <div className="card-body text-center">
            {body}
            </div>
        </div>    
        );
    }
    else{
        return('');
    }
  }
}

export default withNamespaces('app')(Popover);
