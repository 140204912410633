import React, { Component  } from 'react';
import './Macchine.css';
import Loader from './Loader.js'
import { Button,ButtonGroup, Form, FormGroup, Input } from 'reactstrap';

import ReactDatatable from '@ashvin27/react-datatable';
import { withNamespaces } from 'react-i18next';

import IconEdit from 'react-feather/dist/icons/edit';
import IconDelete from 'react-feather/dist/icons/x';
import IconPlus from 'react-feather/dist/icons/plus';

import swal from '@sweetalert/with-react'

const apiUrl=process.env.REACT_APP_MACCHINE_GET;

class Macchine extends Component {
  constructor(props) {
    super(props);
    this.columns = [
        // {
        //     key: "row_id",
        //     text: "ID",
        //     className: "row-id",
        //     align: "left",
        //     sortable: true,
        // },
        {
            key: "description",
            text: "Descrizione",
            className: "description",
            align: "left",
            sortable: true
        },
        {
            key: "action",
            text: "",
            className: "action",
            width: 70,
            align: "right",
            sortable: false,
            cell: record => { 
                return (
                    <ButtonGroup>
                      {/* <Button
                        color="primary"
                        size="sm"
                        onClick={() => this.editRecord(record)}>
                        <IconEdit/>
                      </Button> */}
                      <Button 
                        color="danger"
                        size="sm"
                        onClick={() => this.deleteRecord(record)}>
                        <IconDelete/>
                      </Button>
                    </ButtonGroup>
                );
            }
        }
    ];
    this.config = {
        page_size: 10,
        show_length_menu:false,
        show_first:false,
        show_last:false,
        show_pagination:true,
        show_info:true,
        show_filter:false,
        no_data_text: 'Nessun risultato disponibile',
        length_menu: [ 10, 20, 50 ],
        button: {
            excel: false,
            print: false
        },
        language: {
          length_menu: "Mostra _MENU_ risultati per pagina",
          filter: "Cerca nei risultati...",
          info: "Risultati da _START_ a _END_ di _TOTAL_",
          pagination: {
              first: "Inizio",
              previous: "Prec",
              next: "Succ",
              last: "Fine"
          }
      },
    }
    
    this.state = {
        records: [],
        description:""
    }
    this.addRecord = this.addRecord.bind(this);
  }

  loadData(){     
    this.loaderCallbacks.show("Caricamento dati in corso...");
    

    var myHeaders = new Headers();
    //myHeaders.append("Range", "0-999");
    //myHeaders.append("Range-Unit", "items");
    var myInit = { method: 'GET',
               headers: myHeaders,
               mode: 'cors',
               cache: 'default' };
    
    var path=apiUrl;
        
    
    fetch(path,myInit)
      .then(res => res.json())
      .then(
        (result) => {        
          var height=document.getElementsByClassName('table-container')[0].clientHeight
          
          this.setState({
            gridHeight:height,
            itemsCount:result.length,
            isLoaded: true,
            records: this.formatData(result)            
          });          
          this.loaderCallbacks.hide();
          
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
        
      )
  }
  formatData(items){
    return items.map(function(item) {      
      item.key=item.row_id;
      item.description=!item.description && item.description!==""?"[NULL]":item.description;
      return item;
    });
  }

  addRecord(record) {
    var desc=this.state.description.toUpperCase();
    var myInit = {
      method: 'POST',
      headers:{
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      cache: 'default',                
      body: JSON.stringify({
        description:desc
      }) 
    };
    
    fetch(apiUrl,myInit)
      .then(res => {
        this.setState({
          description: ""
        });
        this.loadData();
      });
  }
  editRecord(record) {
  }

  deleteRecord(record) {

    swal({
      title: "Sei sicuro?",
      text: "Eliminando questa voce non sarà piu selezionabile nella colonna corrispondente delle marginalità e le righe cosi valorizzate risulteranno errate.",
      icon: "warning",
      buttons: ["Annulla", "Continua"],
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        
        var path=apiUrl+"?row_id=eq."+record.row_id; 
        var myInit = {
          method: 'DELETE',      
          mode: 'cors',
          cache: 'default'
        };
        fetch(path,myInit)
          .then(res => {    
            
            swal("La riga selezionata è stata eliminata.", {
              icon: "success",
            });    
            this.loadData();
          },
          (error) => {
            console.log(error);
          }); 
      } else {
      }
    });
  }
  pageChange(pageData) {
  }
  updateDescriptionValue(evt) {
    this.setState({
      description: evt.target.value
    });
  }
  componentDidMount() {
    this.loadData();
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location === nextProps.location
      && this.state.records === nextState.records
      && this.state.description === nextState.description) {
      return false;
    } else {      
      return true;
    }
  }
  loaderMounted (callbacks) {
      this.loaderCallbacks = callbacks
  }
  
  render() {
    const { t } = this.props;
    let header,body;

    header=(
      <div className="card-actions float-right">
        <div className="input-group input-group-sm">
          <Form inline>
            <FormGroup>
              <Input type="text" name="description" id="txtDescription" placeholder="Descrizione" value={this.state.description} onChange={evt => this.updateDescriptionValue(evt)} />
            </FormGroup>
            {' '}
            <Button className="mr-2 ml-2" outline  color="primary" size="sm" onClick={this.addRecord}><IconPlus/></Button>
          </Form>
          <div className="input-group-append">
            {/* <GridMenu 
              OnExportCsv={this.exportCsv.bind(this)} 
              OnClearFilters={this.clearFilters.bind(this)}  
            /> */}
          </div>                
        </div>
      </div>
    );
    
    body=(
      <div className="card-body py-4 ">
        <div className="overflow-hidden table-container">
          <ReactDatatable
              total_record={this.state.records.length}
              config={this.config}
              records={this.state.records}
              columns={this.columns}
              onPageChange={this.pageChange.bind(this)}
          />
        </div>
      </div> 
      
    );
    return(
    <div className="card flex-fill">
        <div className="card-header">          
          {header}
          <h3 className="card-title mb-0">Dati <Loader onMounted={this.loaderMounted.bind(this)} /></h3>
          {/* <h6 className="card-subtitle text-muted">Lorem ipsum ipsem dolor sit amet.</h6> */} 
        </div>
        {body}
      </div>
    );
  }
}

export default withNamespaces('app','settings')(Macchine);
