import React, { Component } from 'react';
import './Home.css';
import { NavLink } from 'react-router-dom';

import { withNamespaces } from 'react-i18next';
const feather =require('feather-icons');

 

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
        feather.replace();
    
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location === nextProps.location) {
      return false;
    } else {      
      return true;
    }
  }
  
  render() {
    const { t,items } = this.props;
    if(items)
    {
    return (      
        <div className="row home-cont">
          <div className="col-12">
            <div className="row">
          {items.map(function(d){
            if(!d.hideLeft)
            {
              return(
                <div className="col-12 col-xl-4 d-flex block-pg" key={d.key}>
                  <div className="card flex-fill">
                    <div className="card-body py-5">
                      <div className="media">
                        <div className="d-inline-block mt-2 mr-4">
                          <i className="feather-lg text-primary" data-feather={d.icon}></i>
                        </div>
                        <div className="media-body">                      
                          <NavLink exact to={d.path} > 
                            <h3 className="mb-3">{t(d.text)}</h3>
                            <div className="mb-0 text-muted">{t(d.desc)}</div>               
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>        
                </div>
              )
          }          
          return("")
          }
          )}
          </div>
          </div>
        </div>
      );
    }
    return("");
  }
}

export default withNamespaces('app','settings')(Home);
