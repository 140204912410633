import React, { Component } from 'react';
import './Grid.css';
import { HotTable } from '@handsontable/react';
import Handsontable from 'handsontable';
import itIT from 'handsontable/es/i18n/languages/it-IT';
import Popover from './Popover.js';
import exportToExcel from '../exportToExcel';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import numbro from 'numbro';
import NitIT from'numbro/dist/languages/it-IT.min.js';
//import pikaday from 'pikaday';
import 'moment/locale/it';

moment.locale("it");
numbro.registerLanguage(NitIT, true);
// numbro.setLanguage('it-IT');

const licenceKey='65bd0-d3f71-0cbd5-94b16-a8003'

Handsontable.languages.registerLanguageDictionary('it-IT', itIT);

(function(Handsontable){
  function checkRevisions(hotInstance, td,prop, cellProperties){    
    let data=hotInstance.getSourceDataAtRow(cellProperties.row)
    let editedCols=data.col_mod_list
    
      td.dataset.hasRevisions=false; 
      if(editedCols)
      { 
        editedCols=","+editedCols+",";
        if(editedCols.indexOf(","+prop+",")>-1)
        {          
          td.dataset.hasRevisions=true;
        }
      }
  }
  function numericRenderer(hotInstance, td, row, column, prop, value, cellProperties) {
    Handsontable.renderers.NumericRenderer.apply(this, arguments);
    checkRevisions(hotInstance, td,prop, cellProperties);
    
  }
  function textRenderer(hotInstance, td, row, column, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    checkRevisions(hotInstance, td,prop, cellProperties);
  }
  
  function dateRenderer(hotInstance, td, row, column, prop, value, cellProperties) {
    Handsontable.renderers.DateRenderer.apply(this, arguments);
    checkRevisions(hotInstance, td,prop, cellProperties);
  }
  
  function checkboxRenderer(hotInstance, td, row, column, prop, value, cellProperties) {
    Handsontable.renderers.CheckboxRenderer.apply(this, arguments);
    checkRevisions(hotInstance, td,prop, cellProperties);
  }
  
  function dropdownRenderer(hotInstance, td, row, column, prop, value, cellProperties) {
    Handsontable.renderers.DropdownRenderer.apply(this, arguments);
    checkRevisions(hotInstance, td,prop, cellProperties);
  }
  // Register an alias
  Handsontable.renderers.registerRenderer('my.numeric', numericRenderer);
  Handsontable.renderers.registerRenderer('my.text', textRenderer);
  Handsontable.renderers.registerRenderer('my.date', dateRenderer);
  Handsontable.renderers.registerRenderer('my.checkbox', checkboxRenderer);
  Handsontable.renderers.registerRenderer('my.dropdown', dropdownRenderer);

})(Handsontable);

class Grid extends Component {
  constructor(props) {
    super(props);
    this.state = {   
    };
    this.exportCsv=this.exportCsv.bind(this);
    this.loadData=this.loadData.bind(this);
    this.clearFilters=this.clearFilters.bind(this);
    this.search=this.search.bind(this);
    //this.afterValidate=this.afterValidate.bind(this);
    this.afterChange=this.afterChange.bind(this);
    this.afterLoadData=this.afterLoadData.bind(this);
    this.afterRender=this.afterRender.bind(this);
    this.afterOnCellMouseOver=this.afterOnCellMouseOver.bind(this);
    this.afterOnCellMouseOut=this.afterOnCellMouseOut.bind(this);
    
    //this.cells=this.cells.bind(this);
    this.hotTableComponent = React.createRef();
    this.hotSettings ={
      licenseKey:'65bd0-d3f71-0cbd5-94b16-a8003',
      rowHeaders:true,
      //dropdownMenu:true,
      dropdownMenu: ["undo","redo", '---------', 'filter_by_condition', 'filter_by_value', 'filter_action_bar'],
      multiColumnSorting:{
        initialConfig: [ {
          column: 0,
          sortOrder: 'asc'
        }]
      },
      headerTooltips: {
        rows: false,
        columns: true,
        onlyTrimmed: false
      },
      colWidths: 100,
      numericFormat: {pattern: '0,00',culture: 'it-IT'},
      viewportColumnRenderingOffset:5,
      viewportRowRenderingOffset:30,
      autoColumnSize :false,
      manualColumnResize:true, 
      manualRowResize:true,
      filters:true,
      formulas:true,
      allowInsertColumn:false,
      allowInsertRow:false,
      allowRemoveColumn:false,
      allowRemoveRow:false,
      persistentState:true,
      //search: true,
      undo: true,
      wordWrap:false,
      stretchH: 'all',
      dateFormat: 'DD/MM/YYYY',
      contextMenu :{
          items:{            
            'undo':"undo",
            'redo':'redo',
            "reset_edit": { // Own custom option
              name: function () { // `name` can be a string or a function
                return 'Annulla modifiche';
              },
              hidden: function () { // `hidden` can be a boolean or a function
                let coords=this.getSelectedLast();
                let row=coords[0];
                let col=coords[1];
                //let cell=this.getCellMeta(row,col);
                let td=this.getCell(row,col);                
                return td.dataset.hasRevisions==="false"; // `this` === hot3
              },
              callback: function(key, selection, clickEvent) { // Callback for specific option
                
                  let row=selection[0].start.row;
                  let col=selection[0].start.col;
                  let cell=this.getCellMeta(row,col);
                  var rowId=this.getDataAtRow(row)[0]
                  //console.log(rowId,cell.prop)
                  var myInit = {
                    method: 'POST',
                    headers:{
                      'Accept': 'application/json, text/plain, */*',
                      'Content-Type': 'application/json'
                    },
                    mode: 'cors',
                    cache: 'default',                
                    body: JSON.stringify({
                      myid_row_provv: rowId, 
                      mycol_name: cell.prop,
                      myvalue:"",
                      myflag:"DEL",
                      user_id:"",
                      myflagdelete:""
                    }) 
                  };
              
                  var url=process.env.REACT_APP_MARGINALITA_UPDATE
                  
                  fetch(url,myInit)
                    .then(res => res.json())
                    .then(res => {
                      myInit = { method: 'GET',
                             mode: 'cors',
                             cache: 'default' };
                  
                      var path=process.env.REACT_APP_MARGINALITA_GET+"?Row_ID=eq."+rowId;
                      return fetch(path,myInit)
                      .then(res => res.json())
                      .then(newData =>{
                        var changes=[]  
                        this.setDataAtRowProp(row,"col_mod_list",newData[0].col_mod_list,"apiReload");
                        for (var key in newData[0]) {
                          if (newData[0].hasOwnProperty(key)) {
                            if(key!=="Row_ID" && key!=="col_mod_list")
                            {
                              changes.push([row,key,newData[0][key]])
                            }
                          }
                        }  
                        this.setDataAtRowProp(changes,"apiReload");
                      })
                    });
              }
            },
            '---------':'---------',
            "hidden_columns_hide":"hidden_columns_hide",
            "hidden_columns_show":"hidden_columns_show"
          },         
        },
      hiddenColumns:{
        columns: [],
        copyPasteEnabled :false,
        indicators: true
      }      
    };
  }
  //col_mod_list
  // cells(row, col, prop){
    
    
  //   if(row===1)
  //   {
  //     console.log(prop)
  //     var data=this.instance.getDataAtRow(row);
  //     console.log(data[data.length-1]);
  //   }

  //   //
  // }
  afterChange(changes,source) {    
    if(changes && source!=="apiReload" && source!=="ColumnSummary.reset" && !source.includes('Validator') )
    {
      //console.log(changes,source);
      //let promiseArray = [];
      let updates=[];
      let upLst=[];
      let context=this;
      changes.forEach(([row, prop, oldValue, newValue]) => {

        var col=context.hotTableComponent.current.hotInstance.propToCol(prop);
        var td=context.hotTableComponent.current.hotInstance.getCell(row,col)
        
        if(oldValue!==newValue && (!td || !td.classList || !td.classList.contains("htInvalid")))
        {
          //console.log("ROW",row)
          var data=context.hotTableComponent.current.hotInstance.getDataAtRow(row);
          //COMMENTA RIGA PER JSON
          //promiseArray.push(context.saveCell(data[0],prop,newValue,row));
          upLst.push({row:data[0],col:prop,val:String(newValue)});
           if(!updates.find((element) => {return element.row === data[0];}))
           {
             updates.push({row:data[0],visualRow:row});
           }
        }        
      });
      //JSON
      context.saveCells(upLst).then(function(){
        // updates.forEach((item) => {
        //   context.getRowData(item.visualRow,item.row);
        // });
        context.getRowsData(updates);
      });
      
      

      // Promise.all(promiseArray).then(function(){
      //   updates.forEach((item) => {
      //     context.getRowData(item.visualRow,item.row);
      //   });
      // });
    }
  }

  saveCells(updates){
    var myInit = {
      method: 'POST',
      headers:{
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      cache: 'default',                
      body: JSON.stringify({
        myid_row_provv: -1, 
        mycol_name: "",
        myvalue:{array:updates},
        myflag:"json",
        user_id:this.props.user,
        myflagdelete:""
      }) 
    };

    var url=process.env.REACT_APP_MARGINALITA_UPDATE
    
    return fetch(url,myInit);
  }

  saveCell(row,col,val,visualRow){
    //console.log(row,col,val)
    //console.log(this);
    var myInit = {
      method: 'POST',
      headers:{
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      cache: 'default',                
      body: JSON.stringify({
        myid_row_provv: row, 
        mycol_name: col,
        myvalue:String(val),
        myflag:"",
        user_id:this.props.user,
        myflagdelete:""
      }) 
    };

    var url=process.env.REACT_APP_MARGINALITA_UPDATE
    
    return fetch(url,myInit);
      // .then(res => res.json())
      //.then(res => {
        // myInit = { method: 'GET',
        //        //headers: myHeaders,
        //        mode: 'cors',
        //        cache: 'default' };
    
        // var path=process.env.REACT_APP_MARGINALITA_GET+"?Row_ID=eq."+row;
        // return fetch(path,myInit)
        // .then(res => res.json())
        // .then(res =>this.updateRow(visualRow,res))
      //});
  }
  //!Non piu utilizzato
  getRowData(visualRow,row)
  {
    var myInit = { method: 'GET',
               //headers: myHeaders,
               mode: 'cors',
               cache: 'default' };
    
      var path=process.env.REACT_APP_MARGINALITA_GET+"?Row_ID=eq."+row;
      return fetch(path,myInit)
      .then(res => res.json())
      .then(res =>this.updateRow(visualRow,res))
  }

  getRowsData(updates)
  {
    
    let instance=this.hotTableComponent.current.hotInstance;
    var rows=[];
    updates.forEach((item) => {
      rows.push(item.row);
        //context.getRowData(item.visualRow,item.row);
      });
    var myInit = { method: 'GET',
               //headers: myHeaders,
               mode: 'cors',
               cache: 'default' };
    
       var path=process.env.REACT_APP_MARGINALITA_GET+"?Row_ID=in.("+rows.join(",")+")";
    return fetch(path,myInit)
      .then(res => res.json())
      .then(res =>{
        //console.log(res);
        var changes=[];
        res.forEach((item) => {
          var visual=updates.find((element) => {
            return element.row === item.Row_ID;
          });
          
          changes=this.updateRow(visual.visualRow,item, changes);
        });


        instance.setDataAtRowProp(changes,"apiReload");
      })
  }


  updateRow(row,newData, chng)
  {
    //console.log(newData);
    var changes=chng;
    changes.push([row,"col_mod_list",newData.col_mod_list])
    //instance.setDataAtRowProp(row,"col_mod_list",newData.col_mod_list,"apiReload");
    for (var key in newData) {
      if (newData.hasOwnProperty(key)) {
        if(key!=="Row_ID" && key!=="col_mod_list")
        {
          changes.push([row,key,newData[key]])
        }
      }
    }  
    return changes;
  }
  // afterValidate (isValid, value, row, prop, source) {
  //     //console.log(isValid, value, row, prop, source)
  //     // if (isValid) {
  //     //   var data=this.hotTableComponent.current.hotInstance.getDataAtRow(row);
  //     //   this.saveCell(data[0],prop,value);      
  //     // }
  // }
  afterOnCellMouseOver(event,coords,td){
    if(td.dataset.hasRevisions==="true")
    {
      var instance=this.hotTableComponent.current.hotInstance;
      var cell=instance.getCellMeta(coords.row,coords.col)
      var rowId=instance.getDataAtRowProp(coords.row,"Row_ID");
      this.popover.show(rowId,cell.prop,td);
    }
  }
  afterOnCellMouseOut(event,coords,td){
    if(td.dataset.hasRevisions==="true")
    {
      this.popover.hide();
    }
  }
  exportCsv(){
      let data=this.hotTableComponent.current.hotInstance.getData();    
      data.unshift(this.props.headers)
      exportToExcel(data,"Marginalità");
  }
  clearFilters(){    
    const filtersPl = this.hotTableComponent.current.hotInstance.getPlugin('filters');
    filtersPl.clearConditions();
    filtersPl.filter();
  }
  loadData(data){    
    this.hotTableComponent.current.hotInstance.loadData(data);
    this.hotTableComponent.current.hotInstance.render();
  }
  search(value){    
    var instance=this.hotTableComponent.current.hotInstance;
    var search = instance.getPlugin('search');
    
    search.query(value);//var queryResult = 
    
    // queryResult.forEach(function(item) {
    //   instance.selectRows(instance.toVisualRow(item.row))      
    // })
    
    
      instance.render();
  }
  updateDimensions() {
    // if(window.innerWidth < 500) {
    //   this.setState({ width: 450, height: 102 });
    // } else {
    //   let update_width  = window.innerWidth-100;
    //   let update_height = Math.round(update_width/4.4);
    //   this.setState({ width: update_width, height: update_height });
    // }

    
    this.hotTableComponent.current.hotInstance.render();
  }
  
  afterLoadData (){
    if(this && this.hotTableComponent.current)
    {
      const MultiColumnSorting = this.hotTableComponent.current.hotInstance.getPlugin('MultiColumnSorting');
      MultiColumnSorting.sort(MultiColumnSorting.getSortConfig());  
      
    }
    if (this.props.onDataLoaded) {this.props.onDataLoaded()}
  }
  afterRender(isForced){
    if(isForced)
    {
      
    }
    
    if (this.props.onRendered) {this.props.onRendered()}
  }
  componentDidMount () {
      if (this.props.onMounted) {
          this.props.onMounted({
            loadData:(data)=>this.loadData(data),
            exportCsv: () => this.exportCsv(),            
            clearFilters: () => this.clearFilters(),            
            search: (value) => this.search(value)
          })
      }
      
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }
  popoverMounted (callbacks) {
    this.popover = callbacks
  }
  render() {
    let { items,headers,cols,summaries} = this.props;
    
    

    // items.push({Row_ID:"TOTALI",Materiali:"=SUM(A1:A" + (items.length - 1)+")"});
    // console.log(items);
    cols.forEach((col)=>{
      switch (col.type)
      {
        case "numeric":
          col.renderer= "my.numeric"          
          break;
        case "dropdown":
          col.renderer= "my.dropdown"          
          break;
        case "text":
          col.renderer= "my.text"          
          break;
        case "checkbox":
          col.renderer= "my.checkbox"          
          break;
        case "date":
          col.renderer= "my.date"          
          break;
        default:
          break;
      }
    });
    this.hotSettings.height=this.props.height-60;
    return (
      <div>
        <HotTable ref={this.hotTableComponent}
          id="hot"
          licenceKey={licenceKey}
          data={items}
          settings={this.hotSettings} 
          colHeaders={headers} 
          columnSummary={summaries}
          columns={cols}  
          language={this.props.lng}
          //afterValidate={this.afterValidate.bind(this)}
          afterChange={this.afterChange.bind(this)}
          afterLoadData={this.afterLoadData.bind(this)}
          afterRender={this.afterRender.bind(this)}
          afterOnCellMouseOver={this.afterOnCellMouseOver.bind(this)}
          afterOnCellMouseOut={this.afterOnCellMouseOut.bind(this)}
        /> 
        <Popover onMounted={this.popoverMounted.bind(this)}  />
      </div>
    );
    
  }
}

export default withNamespaces('common')(Grid);
