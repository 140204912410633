import React, { Component } from 'react';
import './PivotUi.css';

import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';

const PlotlyRenderers = createPlotlyRenderers(Plot);
class PivotUi extends Component {
  constructor(props) {
    super(props);
    
    this.state = props;
    
  }
  componentDidMount () {
      if (this.props.onMounted) {
          this.props.onMounted({
            
          })
      }      
  }
  componentWillUnmount() {
  }

  
  render() {    
    let {items}=this.props;
      return (  
          <PivotTableUI
            data={items}
            unusedOrientationCutoff={0}
            onChange={s => this.setState(s)}
            renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
            {...this.state}
            hiddenFromDragDrop={["col_mod_list"]}
            />
      )
  }
}

export default PivotUi;
