import React, { Component } from 'react';
import './Marginalita.css';

import Grid from './marginalita/Grid.js'
import GridMenu from './marginalita/GridMenu.js'
//import GridSearch from './marginalita/GridSearch.js'
import Loader from './marginalita/Loader.js'
import FilterModal from './marginalita/FilterModal.js'
import JobModal from './marginalita/JobModal.js'
import ImportModal from './marginalita/ImportModal.js'

import IconFilter from 'react-feather/dist/icons/filter';
import IconClock from 'react-feather/dist/icons/clock';
import IconUpload from 'react-feather/dist/icons/upload';


import { Button } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import colsConfig from "./marginalita/cols-config.js"

import moment from 'moment';
//import { CONTEXTMENU_ITEMS_MERGE_CELLS, CONTEXTMENU_ITEMS_SHOW_COLUMN } from 'handsontable/es/i18n/constants';

class Marginalita extends Component {
  constructor(props) {
    super(props);
    this.exportCsv = this.exportCsv.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.search = this.search.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleJobModal = this.toggleJobModal.bind(this);
    this.toggleImportModal = this.toggleImportModal.bind(this);
    this.formatData = this.formatData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.grid = React.createRef();
    this.modal = React.createRef();
    this.modalJob = React.createRef();
    this.state = {      
      itemsCount:0,
      isLoaded: false,
      isLoadedAll: false,
      items:[],
      processi:[],
      gruppi:[],
      voci:[],
      macchine:[],
      showYear:2018,
      isFirstLoad:true,   
      name: "",
      user:"",
      email: "",
      id: ""   
    };

    this.props.keycloak.loadUserInfo().success(userInfo => {
        //console.log(userInfo);
        this.setState({name: userInfo.name, email: userInfo.email, id: userInfo.sub,user:userInfo.preferred_username})
    });

  }
  exportCsv() {      
    this.gridCallbacks.exportCsv();
  }
  clearFilters() {      
    this.gridCallbacks.clearFilters();
  }
  search(value) {      
    this.gridCallbacks.search(value);
  }
  toggleModal(){
    this.modalCallbacks.toggle();
  }
  toggleJobModal(){
    this.modalJobCallbacks.toggle();
  }
  toggleImportModal(){
    this.modalImportCallbacks.toggle();
  }
  gridMounted (callbacks) {
      this.gridCallbacks = callbacks
  }
  gridRendered(){
    this.loaderCallbacks.hide();
  }
  modalMounted (callbacks) {
      this.modalCallbacks = callbacks
  }
  modalJobMounted (callbacks) {
      this.modalJobCallbacks = callbacks
  }
  modalImportMounted (callbacks) {
      this.modalImportCallbacks = callbacks
  }
  loaderMounted (callbacks) {
      this.loaderCallbacks = callbacks
  }
  loadData(startDate,endDate,agenti,clientiFatture,clientiDestinazioni,ov,ignoraPeriodo,numeroFattura,numeroFatturaManuale,fatturabile,eliminato,annoCustom){     
    this.loaderCallbacks.show("Caricamento dati in corso...");
    
    var filtroAgenti="";
    if(agenti && agenti.length>0)
    {
      filtroAgenti="\""+agenti.map((i)=>{return i.value;}).join("\",\"")+"\"";
      filtroAgenti="&Agente=in.("+filtroAgenti+")";
    }
    var filtroClientiFatture="";
    if(clientiFatture && clientiFatture.length>0)
    {
      filtroClientiFatture="\""+clientiFatture.map((i)=>{return i.value;}).join("\",\"")+"\"";
      filtroClientiFatture="&RagSocFattura=in.("+filtroClientiFatture+")";
    }
    var filtroClientiDestinazioni="";
    if(clientiDestinazioni && clientiDestinazioni.length>0)
    {
      filtroClientiDestinazioni="\""+clientiDestinazioni.map((i)=>{return i.value;}).join("\",\"")+"\"";
      filtroClientiDestinazioni="&RagSocCliDestinazione=in.("+filtroClientiDestinazioni+")";
    }    
    var filtroOv="";
    if(ov && ov.length>0)
    {
      filtroOv="\""+ov.map((i)=>{return i.value;}).join("\",\"")+"\"";
      filtroOv="&NumeroOV=in.("+filtroOv+")";
    }    
    var filtroNumeroFattura="";
    if(numeroFattura && numeroFattura.length>0)
    {
      filtroNumeroFattura="\""+numeroFattura.map((i)=>{return i.value;}).join("\",\"")+"\"";
      filtroNumeroFattura="&NumeroFattura=in.("+filtroNumeroFattura+")";
    }
    var filtroNumeroFatturaManuale="";
    if(numeroFatturaManuale && numeroFatturaManuale.length>0)
    {
      filtroNumeroFatturaManuale="\""+numeroFatturaManuale.map((i)=>{return i.value;}).join("\",\"")+"\"";
      filtroNumeroFatturaManuale="&NumeroFatturaManuale=in.("+filtroNumeroFatturaManuale+")";
    }
    var filtroAnnoCustom="";
    if(annoCustom)
    {
      filtroAnnoCustom="&AnnoManuale=eq."+annoCustom+"";
    }
    var filtroFatturabile="";
    if(fatturabile && fatturabile!=="-1")
    {
      filtroFatturabile="&Fatturabile=eq."+fatturabile+"";
    }
    var filtroEliminato="";
    if(eliminato && eliminato!=="-1")
    {
      filtroEliminato="&Eliminato=eq."+eliminato+"";
    }

    var myHeaders = new Headers();
    //myHeaders.append("Range", "0-999");
    //myHeaders.append("Range-Unit", "items");
    var myInit = { method: 'GET',
               headers: myHeaders,
               mode: 'cors',
               cache: 'default' };
    
    var path=process.env.REACT_APP_MARGINALITA_GET;
    if(!ignoraPeriodo)
    { 
      path+="?Periodo=gte."+startDate+
      "&Periodo=lte."+endDate+"";
    }
    
    var filters=filtroAgenti+
    filtroClientiFatture+
    filtroClientiDestinazioni+
    filtroOv+
    filtroNumeroFattura+
    filtroNumeroFatturaManuale+
    filtroAnnoCustom+
    filtroFatturabile+
    filtroEliminato;
    if(ignoraPeriodo && filters.length>0)
    {
        filters="?"+filters.substring(1)
    }
    path+=filters;
    fetch(path,myInit)
      .then(res => res.json())
      .then(
        (result) => {
          this.loaderCallbacks.show("Rendering delle informazioni in corso...");
          
          if(this.gridCallbacks && result.length>0 && this.state.itemsCount>0)
          {
            this.gridCallbacks.loadData(this.formatData(result));
          }
          else{
            var height=document.getElementsByClassName('table-container')[0].clientHeight
            
            this.setState({
              gridHeight:height,
              itemsCount:result.length,
              isLoaded: true,
              items: result            
            });
            if(result.length===0)
            {
              this.loaderCallbacks.hide();
            }
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
        
      )
  }
  formatData(items){
    return items.map(function(item) {
      item.DataFattura=moment(item.DataFattura).format("DD/MM/YYYY")
      item.DataOV=item.DataOV?moment(item.DataOV).format("DD/MM/YYYY"):""
      item.DataTrasporto=item.DataTrasporto?moment(item.DataTrasporto).format("DD/MM/YYYY"):""
      item.DataAggiornamento=item.DataAggiornamento?moment(item.DataAggiornamento).format("DD/MM/YYYY"):""
      item.Periodo=item.Periodo?moment(item.Periodo).format("DD/MM/YYYY"):""
      return item;
    });
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location === nextProps.location
      && this.state.user === nextState.user
       && this.state.items === nextState.items
       && this.state.processi === nextState.processi
       && this.state.gruppi === nextState.gruppi
       && this.state.voci === nextState.voci
       && this.state.macchine === nextState.macchine) {
      return false;
    } else {
      
      return true;
    }
  }
  componentDidMount() {
    
    if(this.state.isFirstLoad)
    {
      var myHeaders = new Headers();
      //myHeaders.append("Range", "0-999");
      //myHeaders.append("Range-Unit", "items");
      var myInit = { method: 'GET',
                headers: myHeaders,
                mode: 'cors',
                cache: 'default' };
      var initArray=[
        this.getProcessi(myInit),
        this.getVoci(myInit),
        this.getGruppi(myInit),
        this.getMacchine(myInit)
      ]      
      Promise.all(initArray).then(([processi,voci,gruppi,macchine]) => {
          this.setState({
              processi,
              gruppi,
              voci,
              macchine,
              isLoadedAll:true
          });
          this.modalCallbacks.toggle();
      });      
    }    
  }

  getProcessi(initFetch){    
    return fetch(process.env.REACT_APP_MARGINALITA_PROCESSI_GET,initFetch)
        .then(res => res.json());
  }
  getVoci(initFetch){
    return fetch(process.env.REACT_APP_MARGINALITA_VOCI_GET,initFetch)
        .then(res => res.json());
  }
  getGruppi(initFetch){
    return fetch(process.env.REACT_APP_MARGINALITA_GRUPPI_GET,initFetch)
        .then(res => res.json());
  }
  getMacchine(initFetch){
    return fetch(process.env.REACT_APP_MARGINALITA_MACCHINE_GET,initFetch)
        .then(res => res.json());
  }
  render() {
    // const { t } = this.props;
    //console.log(this.props.keycloak);
    const { error, isLoaded, items,processi,gruppi,voci,macchine,gridHeight,user } = this.state;
    const cols=colsConfig.cols;
    const headers=colsConfig.headers;
    //const summaries=colsConfig.summaries;

    let colsDb=cols.map(function(i,k){
        return i.data;
    })

    cols[18].source=processi.map(function(item) {
      return item.Processo;
    });
    
    cols[19].source=voci.map(function(item) {
      return item.Voce;
    });

    cols[20].source=gruppi.map(function(item) {
      return item.Gruppo;
    })
    
    
    cols[21].source=macchine.map(function(item) {
      return item.Macchina;
    })

    let header,body;
    if (error) {
      body=(
          <div className="card-body py-4 ">
            <div className="overflow-hidden table-container">
              <div>Error: {error.message}</div>
            </div>
          </div>
      );
    } else if (!isLoaded) {
      body=(
          <div className="card-body py-4 ">
            <div className="overflow-hidden table-container">
              <div></div>
            </div>
          </div>
      );              
        
    } else {
      if(items.length>0)
      {
        var d=this.formatData(items);

        
      }

      var datePickerConfig= {
          firstDay: 1,
          position :"bottom right",
          reposition:false, 
          showWeekNumber: false,
          numberOfMonths:1,
          i18n: {
              previousMonth : 'Mese precedente',
              nextMonth     : 'Mese successivo',
              months        : ['Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno','Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'],
              weekdays      : ['Domenica','Lunedì','Martedì','Mercoledì','Giovedì','Venerdì','Sabato'],
              weekdaysShort : ['Dom','Lun','Mar','Mer','Gio','Ven','Sab']
          }
      }
      cols[9].datePickerConfig=datePickerConfig;
      cols[14].datePickerConfig=datePickerConfig;
      cols[17].datePickerConfig=datePickerConfig;
      //TODO:VERIFICARE NUMERO COLONNA
      //cols[82].datePickerConfig=datePickerConfig;
      //cols[86].datePickerConfig=datePickerConfig;
      header=(
            <div className="card-actions float-right">
              <div className="input-group input-group-sm">
              <Button className="mr-2" outline  color="info" size="sm" onClick={this.toggleImportModal}><IconUpload/></Button>
              <Button className="mr-2" outline  color="info" size="sm" onClick={this.toggleJobModal}><IconClock/></Button>
                <Button className="mr-2" outline  color="primary" size="sm" onClick={this.toggleModal}><IconFilter></IconFilter></Button>
                {/* <GridSearch 
                  OnSearch={this.search.bind(this)}  />{' '}  */}
                <div className="input-group-append">
                  <GridMenu 
                    OnExportCsv={this.exportCsv.bind(this)} 
                    OnClearFilters={this.clearFilters.bind(this)}  
                  />
                </div>                
              </div>
            </div>
          );
      if(items.length>0)
      {
        body= (         
            <div className="card-body py-4 ">
              <div className="overflow-hidden table-container">
                <Grid onMounted={this.gridMounted.bind(this)}  height={gridHeight} user={user}
                ref={this.grid} id="grid" items={d} cols={cols} headers={headers} 
                //summaries={summaries}
                onRendered={this.gridRendered.bind(this)}  />  
              </div>
            </div>   
        );
      } else{
        body= (         
            <div className="card-body py-4 ">
              <div className="overflow-hidden table-container">
                 Nessun risultato trovato.
              </div>
            </div>   
        ); 
      }
      
    }
    
    return (
      <div className="card flex-fill">
        <div className="card-header">          
          {header}
          <h3 className="card-title mb-0">Dati <Loader onMounted={this.loaderMounted.bind(this)} /></h3>
          {/* <h6 className="card-subtitle text-muted">Lorem ipsum ipsem dolor sit amet.</h6> */} 
        </div>
        {body}
        <FilterModal id="modalFilter" ref={this.modal} 
        onLoadDataClicked={this.loadData.bind(this)} 
        onMounted={this.modalMounted.bind(this)} 
        ></FilterModal>
        <JobModal id="modalJob" ref={this.modalJob}  user={user}
        onMounted={this.modalJobMounted.bind(this)} 
        ></JobModal>
        <ImportModal id="modalImport" ref={this.modalImport}  user={user} cols={cols}
        onMounted={this.modalImportMounted.bind(this)} headers={colsDb} 
        ></ImportModal>
      </div>
    );

    
  }
}

export default withNamespaces('common')(Marginalita);
