import React, {Component} from 'react';
import './GridMenu.css';
import {ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {withNamespaces} from 'react-i18next';
import MenuIcon from 'react-feather/dist/icons/more-horizontal';

class GridMenu extends Component {
    constructor(props) {
        super(props);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.exportCsv = this.exportCsv.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.state = {
            tableMenuOpen: false
        };

    }

    exportCsv(event) {
      event.preventDefault();
      this.props.OnExportCsv();      
    }

    clearFilters(event) {
      event.preventDefault();
      this.props.OnClearFilters();      
    }

    toggleMenu() {
        this.setState({
            tableMenuOpen: !this.state.tableMenuOpen
        });
    }

    render() {

        const { t } = this.props;
        return (
            <ButtonDropdown isOpen={this.state.tableMenuOpen} toggle={this.toggleMenu}>
                <DropdownToggle outline size="sm" color="primary">
                    <MenuIcon/>
                </DropdownToggle>
                <DropdownMenu right size="sm">
                    <DropdownItem onClick={this.exportCsv}>{t("common:button.exportCsv")}</DropdownItem>
                    {/* <DropdownItem>Another Action</DropdownItem> */}
                    <DropdownItem divider/>
                    <DropdownItem onClick={this.clearFilters}>{t("common:button.clearFilters")}</DropdownItem>
                </DropdownMenu>
            </ButtonDropdown>
        );
    }
}

export default withNamespaces('common')(GridMenu);
