module.exports ={
    cols:[
        {"data":"Row_ID","type":"numeric", "readOnly":true,numericFormat: {pattern: '0'},width:60},
        {"data":"Anno","type":"numeric", "readOnly":true,numericFormat: {pattern: '0'},width:70},
        {"data":"AnnoManuale","type":"numeric",numericFormat: {pattern: '0'}},
        {"data":"Mese","type":"numeric", "readOnly":true,numericFormat: {pattern: '0'},width:70},
        {"data":"MeseManuale","type":"numeric",numericFormat: {pattern: '0'}},
        {"data":"ClasseFattura","type":"text", "readOnly":true},
        {"data":"TipoFattura","type":"text", "readOnly":true},
        {"data":"NumeroFattura","type":"numeric", "readOnly":true,numericFormat: {pattern: '0'}},
        {"data":"NumeroFatturaManuale","type":"numeric",numericFormat: {pattern: '0'}},
        {"data":"DataFattura","type":"date", "readOnly":true,dateFormat: 'DD/MM/YYYY',correctFormat: true},
        {"data":"RigaBolla","type":"numeric", "readOnly":true,numericFormat: {pattern: '0'}},
        {"data":"SubRigaBolla","type":"numeric", "readOnly":true,numericFormat: {pattern: '0'}},
        {"data":"TipoOV","type":"text", "readOnly":true},
        {"data":"NumeroOV","type":"text", "readOnly":true},
        {"data":"DataOV","type":"date", "readOnly":true,dateFormat: 'DD/MM/YYYY',correctFormat: true},
        {"data":"TipoDDT","type":"text", "readOnly":true},
        {"data":"NrFisDDT","type":"text", "readOnly":true},
        {"data":"DataTrasporto","type":"date", "readOnly":true,dateFormat: 'DD/MM/YYYY',correctFormat: true},
        {"data":"Processo","type":"dropdown"},//COMBO
        {"data":"Voce","type":"dropdown"},//COMBO
        {"data":"Gruppo","type":"dropdown"},//COMBO
        {"data":"Macchina","type":"dropdown"},//COMBO
        {"data":"CodClFattura","type":"text", "readOnly":true, width:120},
        {"data":"RagSocFattura","type":"text", "readOnly":true, width:250},
        {"data":"CodClDDT","type":"text", "readOnly":true, width:120},
        {"data":"RagSocDDT","type":"text", "readOnly":true, width:250},
        {"data":"CodCliDestinazione","type":"text", "readOnly":true, width:120},
        {"data":"RagSocCliDestinazione","type":"text", "readOnly":true, width:250},
        {"data":"StatoCliDestinazione","type":"text", "readOnly":true, width:120},
        {"data":"RifInt","type":"text", "readOnly":true, width:250},
        {"data":"StatoClDDT","type":"text", "readOnly":true, width:120},
        {"data":"SiglaAgente","type":"text", "readOnly":true},
        {"data":"Agente","type":"text", "readOnly":true, width:250},
        {"data":"Provincia","type":"text", "readOnly":true},
        {"data":"Regione","type":"text", "readOnly":true},
        {"data":"Attivita","type":"text", "readOnly":true},
        {"data":"Commessa","type":"text", "readOnly":true},
        {"data":"NrOP","type":"text", "readOnly":true},
        {"data":"CodiceArticolo","type":"text", "readOnly":true},
        {"data":"DescrArticolo","type":"text", "readOnly":true, width:250},
        {"data":"TipGes","type":"text", "readOnly":true},
        {"data":"GesMag","type":"text", "readOnly":true},
        {"data":"QtaCons","type":"text", "readOnly":true},
        {"data":"ImpValTot","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Valuta","type":"text", "readOnly":true, width:70},
        {"data":"ImpEuro","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"ImpEuroTot","type":"numeric", "readOnly":false, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Materiali","type":"numeric", "readOnly":false, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"MaterialiSuDistintaBase","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"LavEsterne","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"SpeseDirette","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Resi","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Somma_Materiali","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Costo_KM","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Totale_costi","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Provvigioni","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Ag1","type":"text", "readOnly":true},
        {"data":"Ag1_Provvigioni","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Ag2","type":"text", "readOnly":true},
        {"data":"Ag2_Provvigioni","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Provvigioni_Pipe_tube","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Provvigioni_tube_process","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Provvigioni_Tekbend","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Provvigioni_Tubetech","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Provvigioni_Granitsas","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Provvigioni_KSC_indirect_Sales","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Provvigioni_Sala","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Provvigioni_Tecnotub","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Provvigioni_Eremeeva","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Provvigioni_Promarketing","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Provvigioni_Westbrook","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Provvigioni_altro","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Provvigioni_Manuali","type":"numeric", numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"Totale_provvigioni","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"NumeroOre","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"TotCostoOre","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"UnicoDDT","type":"text", "readOnly":true},
        {"data":"UnicoFatt","type":"text", "readOnly":true},
        {"data":"UnicoRigaDDT","type":"text", "readOnly":true},
        {"data":"MaxValore","type":"checkbox", "readOnly":true,checkedTemplate: '1',uncheckedTemplate: '0',className:"htCenter"},
        {"data":"Qtaric","type":"numeric", "readOnly":true},
        {"data":"DuplicatoRiga","type":"checkbox", "readOnly":true,checkedTemplate: '1',uncheckedTemplate: '0',className:"htCenter"},
        {"data":"Aggiornabile","type":"checkbox", "readOnly":false,checkedTemplate: '1',uncheckedTemplate: '0',className:"htCenter"},
        {"data":"Fatturabile","type":"checkbox", "readOnly":false,checkedTemplate: '1',uncheckedTemplate: '0',className:"htCenter"},
        {"data":"Eliminato","type":"checkbox", "readOnly":false,checkedTemplate: '1',uncheckedTemplate: '0',className:"htCenter"},
        {"data":"DataAggiornamento","type":"date", "readOnly":true,dateFormat: 'DD/MM/YYYY',correctFormat: true},
        {"data":"SpeseDiretteOV","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"SpeseDiretteCLE","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"SpeseDiretteFV","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},
        {"data":"TrasportiClienteFatt","type":"numeric", "readOnly":true, numericFormat: {pattern: '0,0.00',culture: 'it-IT'}},        
        {"data":"Periodo","type":"date", "readOnly":true,dateFormat: 'DD/MM/YYYY',correctFormat: true},        
        {"data":"AttivitaDestinazione","type":"text", "readOnly":true},
        {"data":"Unico_DDT_Duplicato","type":"numeric", "readOnly":true,numericFormat: {pattern: '0'}},
        
        // {"data":"col_mod_list","type":"text", "readOnly":true,copyPasteEnabled:false},

    ],
    summaries:[
        {
            destinationRow: 0,
            destinationColumn: 0,
            reversedRowCoords: true,
            type: 'count',
            forceNumeric: true
        }
        ,
        {
            destinationRow: 0,
            reversedRowCoords: true,
            destinationColumn: 46,
            type: 'sum',
            forceNumeric: true
        }
    ],
    headers:[
        "ID",
        "Anno",
        "Anno Custom",
        "Mese",
        "Mese Custom",
        "Classe Fattura",
        "Tipo Fattura",
        "Numero Fattura",
        "Numero Fattura Custom",
        "Data Fattura",
        "Riga Bolla",
        "SubRiga Bolla",
        "Tipo OV",
        "Numero OV",
        "Data OV",
        "Tipo DDT",
        "Nr Fis DDT",
        "Data Trasporto",
        "Processo",
        "Voce",
        "Gruppo",
        "Macchina",
        "Cod Cl Fattura",
        "Rag Soc Fattura",
        "Cod Cl DDT",
        "Rag Soc DDT",
        "Cod Cli Destinazione",
        "Rag Soc Cli Destinazione",
        "Stato Cli Destinazione",
        "Rif Int",
        "Stato Cl DDT",
        "Sigla Agente",
        "Agente",
        "Provincia",
        "Regione",
        "Attivita",
        "Commessa",
        "Nr OP",
        "Codice Articolo",
        "Descr Articolo",
        "Tip Ges",
        "Ges Mag",
        "Qta Cons",
        "Imp Val Tot",
        "Valuta",
        "Imp Euro",
        "Imp Euro Tot",
        "Materiali",
        "Materiali Su Distinta Base",
        "Lav Esterne",
        "Spese Dirette",
        "Resi",
        "Somma Materiali",
        "Costo KM",
        "Totale costi",
        "Provvigioni",
        "Agente 1",
        "Provvigioni Agente 1",
        "Agente 2",
        "Provvigioni Agente 2",
        "Provvigioni Pipe tube",
        "Provvigioni tube process",
        "Provvigioni Tekbend",
        "Provvigioni Tubetech",
        "Provvigioni Granitsas",
        "Provvigioni KSC indirect Sales",
        "Provvigioni Sala",
        "Provvigioni Tecnotub",
        "Provvigioni Eremeeva",
        "Provvigioni Promarketing",
        "Provvigioni Westbrook",
        "Provvigioni altro",
        "Provvigioni Manuali",
        "Totale provvigioni",
        "Numero Ore",
        "Tot Costo Ore",
        "Unico DDT",
        "Unico Fatt",
        "Unico Riga DDT",
        "Max Valore",
        "Qta Ric",
        "Duplicato Riga",
        "Aggiornabile",
        "Fatturabile",
        "Eliminato",
        "Data Aggiornamento",
        "Spese Dirette OV",
        "Spese Dirette CLE",
        "Spese Dirette FV",
        "Trasporti Cliente Fatt",
        "Periodo",
        "Attivita Destinazione",
        "Unico DDT duplicato"
        //, "Celle modificate"
    ]
}




