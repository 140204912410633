import React, { Component  } from 'react';
import './Schedulazioni.css';
import Loader from './Loader.js'
//import { Button,ButtonGroup, Form, FormGroup, Input } from 'reactstrap';

import ReactDatatable from '@ashvin27/react-datatable';
import { withNamespaces } from 'react-i18next';

//import IconEdit from 'react-feather/dist/icons/edit';
//import IconDelete from 'react-feather/dist/icons/x';
//import IconPlus from 'react-feather/dist/icons/plus';

import swal from '@sweetalert/with-react'
import moment from 'moment';
import numbro from 'numbro';
import NitIT from'numbro/dist/languages/it-IT.min.js';
import 'moment/locale/it';

moment.locale("it");
numbro.registerLanguage(NitIT, true);

const apiUrl=process.env.REACT_APP_SCHEDULAZIONI_GET;

class Macchine extends Component {
  constructor(props) {
    super(props);
    this.columns = [
        {
            key: "user_id",
            text: "User",
            className: "user-id",
            align: "left",
            sortable: true,
        },
        {
            key: "monthcd",
            text: "Month",
            className: "monthcd",
            align: "left",
            sortable: true,
            cell: record => { 
              return moment( record.monthcd+"01", "YYYYMMDD").format("MM/YYYY");
            }
        },
        {
            key: "nr_fatt",
            text: "Nr. Fattura",
            className: "nr-fatt",
            align: "right",
            sortable: true,
            cell: record => { 
              return (
                <span className="d-block text-right">
                  {record.nr_fatt}
                </span>
              )
            }
        },
        {
            key: "nr_attemps",
            text: "Nr. Tentativi",
            className: "nr-attemps",
            align: "right",
            sortable: true,
            cell: record => { 
              return (
                <span className="d-block text-right">
                  {record.nr_attemps}
                </span>
              )
            }
        },
        {
            key: "creation_time",
            text: "Data creazione",
            className: "creation-time",
            align: "left",
            sortable: true,
            cell: record => { 
              return moment( record.creation_time).format("LLLL");
            }
        },
        {
            key: "start_time",
            text: "Data inizio",
            className: "start-time",
            align: "left",
            sortable: true,
            cell: record => { 
              return record.start_time? moment( record.start_time).format("LLLL"):""
            }
        },
        {
            key: "end_time",
            text: "Data fine",
            className: "end-time",
            align: "left",
            sortable: true,
            cell: record => { 
              return record.end_time? moment( record.end_time).format("LLLL"):"";
            }
        },
        {
            key: "status",
            text: "Stato",
            className: "status",
            align: "center",
            sortable: true,
            cell: record => { 
              let lbl="";
              let css=""
              if(record.end_time)
              {
                lbl="FINISHED";
                css="badge-success"
              }
              else if(record.start_time && record.nr_attemps<=10)
              {
                lbl="RUNNING";
                css="badge-info"
              }
              else if(record.start_time && record.nr_attemps>10)
              {
                lbl="FAILED";
                css="badge-danger"
              }
              else
              {
                lbl="PENDING";
                css="badge-warning"
              }
              return (<span className={"d-block badge "+css} >{lbl}</span>)
            }
        },
        // {
        //     key: "action",
        //     text: "",
        //     className: "action",
        //     width: 70,
        //     align: "right",
        //     sortable: false,
        //     cell: record => { 
        //         return (
        //             <ButtonGroup>
        //               {/* <Button
        //                 color="primary"
        //                 size="sm"
        //                 onClick={() => this.editRecord(record)}>
        //                 <IconEdit/>
        //               </Button> */}
        //               <Button 
        //                 color="danger"
        //                 size="sm"
        //                 onClick={() => this.deleteRecord(record)}>
        //                 <IconDelete/>
        //               </Button>
        //             </ButtonGroup>
        //         );
        //     }
        // }
    ];
    this.config = {
        sort:{ column: "creation_time", order: "desc" },
        fixedHeader: true,
        page_size: 10,
        show_length_menu:false,
        show_first:false,
        show_last:false,
        show_pagination:true,
        show_info:true,
        show_filter:false,
        no_data_text: 'Nessun risultato disponibile',
        length_menu: [ 10, 20, 50 ],
        button: {
            excel: false,
            print: false
        },
        language: {
          length_menu: "Mostra _MENU_ risultati per pagina",
          filter: "Cerca nei risultati...",
          info: "Risultati da _START_ a _END_ di _TOTAL_",
          pagination: {
              first: "Inizio",
              previous: "Prec",
              next: "Succ",
              last: "Fine"
          }
      },
    }
    
    this.state = {
        records: [],
        description:""
    }
    //this.addRecord = this.addRecord.bind(this);
  }

  loadData(){     
    this.loaderCallbacks.show("Caricamento dati in corso...");
    

    var myHeaders = new Headers();
    //myHeaders.append("Range", "0-999");
    //myHeaders.append("Range-Unit", "items");
    var myInit = { method: 'GET',
               headers: myHeaders,
               mode: 'cors',
               cache: 'default' };
    
    var path=apiUrl;
        
    
    fetch(path,myInit)
      .then(res => res.json())
      .then(
        (result) => {        
          var height=document.getElementsByClassName('table-container')[0].clientHeight
          
          this.setState({
            gridHeight:height,
            itemsCount:result.length,
            isLoaded: true,
            records: this.formatData(result)            
          });          
          this.loaderCallbacks.hide();
          
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
        
      )
  }
  formatData(items){
    return items.map(function(item) {      
      item.key=item.row_id;
      //item.description=!item.description && item.description!==""?"[NULL]":item.description;
      return item;
    });
  }

 

  deleteRecord(record) {

    swal({
      title: "Sei sicuro?",
      text: "Eliminando questa voce non sarà piu selezionabile nella colonna corrispondente delle marginalità e le righe cosi valorizzate risulteranno errate.",
      icon: "warning",
      buttons: ["Annulla", "Continua"],
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        
        var path=apiUrl+"?row_id=eq."+record.row_id; 
        var myInit = {
          method: 'DELETE',      
          mode: 'cors',
          cache: 'default'
        };
        fetch(path,myInit)
          .then(res => {    
            
            swal("La riga selezionata è stata eliminata.", {
              icon: "success",
            });    
            this.loadData();
          },
          (error) => {
            console.log(error);
          }); 
      } else {
      }
    });
  }
  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }
  updateDescriptionValue(evt) {
    this.setState({
      description: evt.target.value
    });
  }
  componentDidMount() {
    this.loadData();
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location === nextProps.location
      && this.state.records === nextState.records
      && this.state.description === nextState.description) {
      return false;
    } else {      
      return true;
    }
  }
  loaderMounted (callbacks) {
      this.loaderCallbacks = callbacks
  }
  
  render() {
    const { t } = this.props;
    console.log(this.props)
    let header,body;

    header=(
      <div className="card-actions float-right">
        <div className="input-group input-group-sm">              
        </div>
      </div>
    );
    
    body=(
      <div className="card-body py-4 ">
        <div className="overflow-hidden table-container">
          <ReactDatatable
              total_record={this.state.records.length}
              config={this.config}
              records={this.state.records}
              columns={this.columns}
              onPageChange={this.pageChange.bind(this)}
          />
        </div>
      </div> 
      
    );
    return(
    <div className="card flex-fill">
        <div className="card-header">          
          {header}
          <h3 className="card-title mb-0">Dati <Loader onMounted={this.loaderMounted.bind(this)} /></h3>
          {/* <h6 className="card-subtitle text-muted">Lorem ipsum ipsem dolor sit amet.</h6> */} 
        </div>
        {body}
      </div>
    );
  }
}

export default withNamespaces('app','settings')(Macchine);
