import React, { Component } from 'react';
import './Grid.css';
import { HotTable } from '@handsontable/react';
import Handsontable from 'handsontable';
import itIT from 'handsontable/es/i18n/languages/it-IT';

import exportToExcel from '../exportToExcel';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import numbro from 'numbro';
import NitIT from'numbro/dist/languages/it-IT.min.js';
//import pikaday from 'pikaday';
import 'moment/locale/it';

moment.locale("it");
numbro.registerLanguage(NitIT, true);
// numbro.setLanguage('it-IT');
Handsontable.languages.registerLanguageDictionary('it-IT', itIT);



class Grid extends Component {
  constructor(props) {
    super(props);
    this.state = {   
    };
    this.exportCsv=this.exportCsv.bind(this);
    this.loadData=this.loadData.bind(this);
    this.clearFilters=this.clearFilters.bind(this);
    this.search=this.search.bind(this);
    this.afterLoadData=this.afterLoadData.bind(this);
    this.afterRender=this.afterRender.bind(this);
    
    //this.cells=this.cells.bind(this);
    this.hotTableComponent = React.createRef();
    var grid=this;
    this.hotSettings ={
      rowHeaders:true,
      //dropdownMenu:true,
      dropdownMenu: ['filter_by_condition', 'filter_by_value', 'filter_action_bar'],
      multiColumnSorting:{
        initialConfig: [ {
          column: 0,
          sortOrder: 'asc'
        }]
      },
      colWidths: 100,
      //numericFormat: {pattern: '0,0.00',culture: 'it-IT'},
      viewportColumnRenderingOffset:5,
      viewportRowRenderingOffset:30,
      autoColumnSize :false,
      manualColumnResize:true, 
      manualRowResize:true,
      filters:true,
      formulas:true,
      allowInsertColumn:false,
      allowInsertRow:false,
      allowRemoveColumn:false,
      allowRemoveRow:false,
      persistentState:true,
      //search: true,
      undo: true,
      wordWrap:false,
      stretchH: 'all',
      dateFormat: 'DD/MM/YYYY',
      contextMenu :{
          items:{ 
            "reset_edit": { // Own custom option
              name: function () { // `name` can be a string or a function
                return 'Utilizza come regola per codice/prodotto';
              },
              hidden: function () { // `hidden` can be a boolean or a function
                let coords=this.getSelectedLast();
                let row=coords[0];
                let col=coords[1];                
                let cell=this.getCellMeta(row,col);                
                //var cod=this.getDataAtRowProp(row,"Codice");
                //var prod=this.getDataAtRowProp(row,"Prodotto");
                //let td=this.getCell(row,col);                
                return  !(cell.prop==="Codice"||cell.prop==="Prodotto"||cell.prop==="NonPreventivato"); // !(cod && prod) &&
              },
              callback: function(key, selection, clickEvent) { // Callback for specific option
                
                  let row=selection[0].start.row;
                  let col=selection[0].start.col;
                  let cell=this.getCellMeta(row,col);
                  var cod=this.getDataAtRowProp(row,"Codice");
                  var prod=this.getDataAtRowProp(row,"Prodotto");
                  //var billable=this.getDataAtRowProp(row,"NonPreventivato");

                  var filtroCodice=cod?"eq."+cod:"is.null";
                  var filtroProdotto=prod?"eq."+prod:"is.null";

                  var myInitGet = { method: 'GET',
                  mode: 'cors',
                  cache: 'default' };
                  var path=process.env.REACT_APP_CODICI_PRODOTTO_GET+"?codice="+filtroCodice+"&prodotto="+filtroProdotto;    
                  fetch(path,myInitGet)
                    .then(res => res.json())
                    .then(
                      (result) => {
                          var myInit = {
                            method: result.length>0?'PATCH':'POST',
                            headers:{
                              'Accept': 'application/json, text/plain, */*',
                              'Content-Type': 'application/json'
                            },
                            mode: 'cors',
                            cache: 'default',                
                            body: JSON.stringify({
                              codice: cod,
                              prodotto:prod,
                              billable:true
                            }) 
                          };
                          fetch(path,myInit)
                            .then(res => {
                              if (grid.props.onNeedRefresh) {grid.props.onNeedRefresh()}
                            });  
                      },
                      // Note: it's important to handle errors here
                      // instead of a catch() block so that we don't swallow
                      // exceptions from actual bugs in components.
                      (error) => {

                      }
                      
                    )
              }
            },            
            "hidden_columns_hide":"hidden_columns_hide",
            "hidden_columns_show":"hidden_columns_show"
          },         
        },
      hiddenColumns:{
        columns: [],
        copyPasteEnabled :false,
        indicators: true
      }      
    };
  }
  
  exportCsv(){
      let data=this.hotTableComponent.current.hotInstance.getData();      
      data.unshift(this.props.headers)
      exportToExcel(data,"Preventivi Consuntivi");
  }
  clearFilters(){    
    const filtersPl = this.hotTableComponent.current.hotInstance.getPlugin('filters');
    filtersPl.clearConditions();
    filtersPl.filter();
  }
  loadData(data){    
    this.hotTableComponent.current.hotInstance.loadData(data);
    this.hotTableComponent.current.hotInstance.render();
  }
  search(value){    
    var instance=this.hotTableComponent.current.hotInstance;
    var search = instance.getPlugin('search');
    
    search.query(value);//var queryResult = 
    
    
      instance.render();
  }
  updateDimensions() {
    // if(window.innerWidth < 500) {
    //   this.setState({ width: 450, height: 102 });
    // } else {
    //   let update_width  = window.innerWidth-100;
    //   let update_height = Math.round(update_width/4.4);
    //   this.setState({ width: update_width, height: update_height });
    // }

    if( this.hotTableComponent&&  this.hotTableComponent.current &&  this.hotTableComponent.hotInstance)
    {
      this.hotTableComponent.current.hotInstance.render();
    }
  }
  
  afterLoadData (){
    // restore sorting after data is refreshed
    if(this && this.hotTableComponent.current)
    {
      const MultiColumnSorting = this.hotTableComponent.current.hotInstance.getPlugin('MultiColumnSorting');
      MultiColumnSorting.sort(MultiColumnSorting.getSortConfig());
    }
    if (this.props.onDataLoaded) {this.props.onDataLoaded()}
  }
  afterRender(){    
    if (this.props.onRendered) {this.props.onRendered()}
  }
  componentDidMount () {
      if (this.props.onMounted) {
          this.props.onMounted({
            loadData:(data)=>this.loadData(data),
            exportCsv: () => this.exportCsv(),            
            clearFilters: () => this.clearFilters(),            
            search: (value) => this.search(value)
          })
      }
      
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }
  popoverMounted (callbacks) {
    this.popover = callbacks
  }
  render() {
    let { items,headers,cols } = this.props;    
    this.hotSettings.height=this.props.height-60;
    return (
      <div>
        <HotTable ref={this.hotTableComponent}
          id="hot"
          data={items}
          settings={this.hotSettings} 
          colHeaders={headers} 
          columns={cols}  
          language={this.props.lng}
          afterLoadData={this.afterLoadData.bind(this)}
          afterRender={this.afterRender.bind(this)}
        /> 
      </div>
    );
    
  }
}

export default withNamespaces('common')(Grid);
