import React, { Component } from 'react';
import './LeftNav.css';
import { withNamespaces } from 'react-i18next';
import { NavLink,matchPath } from 'react-router-dom'
const feather =require('feather-icons');

class LeftNav extends Component {
    componentDidMount(){
        feather.replace();
        if (this.props.onMounted) {
            this.props.onMounted({
            toggle: () => this.toggle()
            })
        }   
    }
    componentDidUpdate(){
        feather.replace();
    }
    constructor(props){
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { "showHideSidenav": "toggled" };
        this.renderChildren = this.renderChildren.bind(this)
    }
    toggle() {
        this.setState({
            "showHideSidenav":(this.state.showHideSidenav==="toggled") ? "":"toggled"
        });
      }
    renderChildren() {
        const { t } = this.props;
        const pathName=this.props.location.pathname;
        return this.props.children.map(function(d){
            if(!d.hideLeft)
            {                
                var match = matchPath(d.path, {
                    path: pathName,
                    exact: true,
                    strict: false
                });

                if(match)
                {
                    return(
                        <li className="sidebar-item active" key={d.key}>
                            <span className="sidebar-link">                        
                                <i className="align-middle" data-feather={d.icon}></i>
                                <span className="align-middle">{t(d.text)}</span>
                            </span>
                        </li>
                        )
                }
                return (
                    <li className="sidebar-item" key={d.key}>
                        <NavLink exact to={d.path} className="sidebar-link">                        
                            <i className="align-middle" data-feather={d.icon}></i>
                            <span className="align-middle">{t(d.text)}</span> 
                        </NavLink>
                    </li>
                );
            }
            
            return ("");
        });
        
    }
    render() {

        const { t } = this.props;
        return (      
            <nav className={"sidebar sidebar-sticky "+this.state.showHideSidenav}>
                <div className="sidebar-content">
                    
                    <NavLink exact to="/" className="sidebar-brand" >
                        <i className="align-middle" data-feather="box"></i>
                        <span className="align-middle">{t("settings:appName")}</span>
                    </NavLink>
                    <ul className="sidebar-nav">
                        {this.renderChildren()}                        
                    </ul>
                </div>
            </nav>
        );
    }
}

export default withNamespaces('app','settings')(LeftNav);
